import { makeStyles, Theme } from '@material-ui/core';
import { IThemeOptions } from '@components/theme';
import paddingStyleConstants from '@components/styles/padding.constants';
import textConstants from '@components/styles/text.constants';
import { pxToEm } from '@shared/utils/styles';

export const useDashboardLayoutStyles = makeStyles<Theme & IThemeOptions>(
  ({ color: { base } }) => ({
    layoutRoot: {
      minWidth: '1200px',
      minHeight: '400px',
    },
    wrapper: {
      top: 0,
      bottom: 0,
      right: 0,
      overflowX: 'auto',
      overflowY: 'hidden',
      position: 'absolute',
      backgroundColor: base,
    },
    wrapperLeftShort: {
      left: '5em',
    },
    wrapperLeftLong: {
      left: '15em',
    },
    pageErrorBody: {
      marginTop: pxToEm(250),
    },
  }),
  {
    index: 1,
  }
);

export const useDashboardHeaderStyles = makeStyles(
  ({ color: { base, tertiary, danger, links, primary, warning }, breakpoints: { down } }) => ({
    '@keyframes open': {
      '0%': {
        opacity: 0,
      },
      '100%': {
        opacity: 1,
      },
    },
    root: {
      left: 0,
      right: 0,
      top: 0,
      bottom: 'auto',
      height: '5em',
      maxHeight: '5em',
      backgroundColor: 'inherit',
      width: 'auto',
      position: 'absolute',
      [down('md')]: {
        width: '1200px',
      },
    },
    header: {
      width: '100%',
      height: '5em',
      padding: '0 2em 0',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    actions: {
      display: 'flex',
    },
    content: {
      height: '2em',
      width: '100%',
      padding: '0 2em',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    backButton: {
      color: tertiary[900],
      minWidth: 'auto',
      padding: '0.25em 2px 0.25em 0',
      border: 'none',
      marginRight: '1.5em',
      borderRadius: '2px',
      background: 'transparent',
      height: '2em',
      width: '2em',
      '&:hover': {
        backgroundColor: tertiary[100],
        borderRadius: '0.375em',
      },
    },
    buttonMenuIcon: {
      display: 'flex',
      backgroundColor: tertiary[100],
      marginTop: '0.25em',
      marginLeft: '1em',
      width: '2em',
      height: '2em',
      alignItems: 'center',
      borderRadius: '6px',
      '&:hover': {
        cursor: 'pointer',
        backgroundColor: tertiary[200],
        transition: '0.3s ease',
      },
    },
    buttonIcon: {
      margin: 'auto',
    },
    backTitleBlock: {
      display: 'flex',
      alignItems: 'center',
    },
    rightMenu: {
      width: '20em',
    },
    menuBackground: {
      background: base,
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
    },
    menuBackgroundDemo: {
      backgroundColor: tertiary[800],
    },
    menuTopContainer: {
      padding: '1em 0',
      backgroundColor: tertiary[800],
      '& > span': {
        marginLeft: '1.5em',
      },
    },
    menuMidContainer: {
      padding: '1em 0 1em 2em',
      backgroundColor: primary[700],
    },
    menuMidContainerWarning: {
      padding: '1em 0 1em 2em',
      backgroundColor: warning[600],
    },
    menuAccountContainer: {
      padding: '0.75em 0 0.75em 2em',
      backgroundColor: primary[800],
    },
    menuBottomContainer: {
      height: '100%',
      padding: '2em',
      textAlign: 'center',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    menuBottomContainerDemo: {
      height: '100%',
      padding: '2em',
      textAlign: 'center',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      backgroundColor: base,
    },
    userMenuBalloonWrapper: {
      position: 'absolute',
      marginTop: '2.75em',
      borderRadius: '0.75em',
      flexDirection: 'column',
      width: '16.5em',
      backgroundColor: 'white',
      zIndex: 100,
      filter: `drop-shadow(0 0 0.25em ${tertiary[300]})`,
      animation: '$open linear 0.2s',
      top: '2em',
      paddingBottom: '0.5em',
      cursor: 'default',
    },
    userMenuWithFinancialMenu: {
      right: '4.75em',
    },
    userMenuWithoutFinancialMenu: {
      right: '1.75em',
    },
    userBalloonItemRows: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    userAvailableAtcContainer: {
      backgroundColor: tertiary[50],
    },
    userAvailableAtsTitle: {
      margin: '0.75em 0 0.75em 1em',
    },
    userBalloonItemRow: {
      height: '2.5em',
      width: '100%',
      display: 'flex',
      justifyContent: 'start',
      alignItems: 'center',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      '&:hover': {
        cursor: 'pointer',
        backgroundColor: tertiary[100],
      },
    },
    userBalloonIcon: {
      margin: '2px 0.75em 0 1em',
      color: 'black',
    },
    userBalloonAvailableIcon: {
      margin: '2px 0.75em 0 1em',
      minWidth: '1em',
      transform: 'rotate(270deg)',
      color: 'black',
    },
    userDomain: {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      marginRight: '0.5em',
    },
    userAddAtsBtn: {
      margin: '1em',
      color: links[600],
      backgroundColor: 'inherit',
    },
    userBalloonLogoutIcon: {
      color: danger[600],
    },
    userInfoContainer: {
      paddingTop: '1em',
      paddingBottom: '1em',
      borderBottom: `1px solid ${tertiary[200]}`,
      flexDirection: 'column',
    },
    userInfoText: {
      marginLeft: '1em',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      lineHeight: '1.6',
    },
    menuItems: {
      padding: '0.5em 0',
    },
    link: {
      padding: '0.75em 2em',
      color: base,
      display: 'flex',
      alignItems: 'center',
      textDecoration: 'none',
      '& *': {
        lineHeight: '1em',
        '&:first-child': {
          marginRight: '1.5em',
        },
      },
    },
    iconWrapper: {
      width: '1em',
    },
    linkWrapper: {
      '&:hover *': {
        backgroundColor: tertiary[700],
      },
    },
    menuBalanceLine: {
      display: 'flex',
      alignItems: 'center',
      paddingTop: '0.5em',
      flexWrap: 'wrap',
    },
    menuBalanceNumber: {
      marginRight: '0.5em',
      whiteSpace: 'nowrap',
    },
    menuBalanceNumberCredit: {
      whiteSpace: 'nowrap',
    },
    menuArrowDown: {
      transform: 'rotate(180deg)',
      color: tertiary[900],
      margin: '1em 0 0.5em',
    },
    button: {
      width: '100%',
      '&:hover': {
        color: tertiary[900],
      },
    },
    downloadTemplateLink: {
      marginTop: '1em',
      display: 'flex',
      alignItems: 'center',
      textDecoration: 'none',
      color: base,
      '& *': {
        lineHeight: '1em',
        margin: '0 0.25em',
      },
    },
    profileButton: {
      alignItems: 'flex-end',
    },
    userDataBlockWrapper: {
      display: 'flex',
      alignItems: 'center',
      '&:hover': {
        cursor: 'pointer',
      },
    },
    userNameWrapper: {
      cursor: 'pointer',
      textAlign: 'end',
    },
    userAvatar: {
      width: '2em',
      height: '2em',
      marginLeft: '0.75em',
      backgroundColor: danger[500],
    },
    balanceUnavailable: {
      display: 'flex',
      alignItems: 'center',
    },
    infoIcon: {
      width: '3.5em',
      height: '2em',
      marginRight: '1.25em',
      marginLeft: '3em',
      color: base,
    },
    ...paddingStyleConstants,
    ...textConstants,
  })
);
