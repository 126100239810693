import React from 'react';
import { LogoBlackIcon } from '@shared/assets/images/icons/logos';
import { Tele2Icon, SipIcon } from '@shared/assets/images/icons';
import { ICellProps } from '@components/LazyTable';
import { NumberType, NumbersUnionModel } from '@/client/generated/graphql';

import { useIncomingNumbersStyles } from '../IncomingNumbers.styles';

export const IncomingNumberTypeCell = ({ item }: ICellProps<NumbersUnionModel>) => {
  const classes = useIncomingNumbersStyles();
  if (item.__typename === 'NumbersModel') {
    if (item.type === NumberType.Own || item.type === NumberType.OwnTrunk) {
      return (
        <div className={classes.numberTypeBody}>
          <SipIcon className={classes.icon} width={16} height={16} />
        </div>
      );
    }
    return (
      <div className={classes.numberTypeBody}>
        <LogoBlackIcon className={classes.icon} width={16} height={16} />
      </div>
    );
  }
  return (
    <div className={classes.numberTypeBody}>
      <Tele2Icon className={classes.icon} width={24} height={24} />
    </div>
  );
};
