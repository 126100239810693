import React, { useMemo, useState } from 'react';
import Breadcrumbs from '@/components/Breadcrumbs';
import { useQuery } from '@apollo/client';
import { GET_DOCUMENTS_QUERY, USER_QUERY } from '@/client/queries';
import { getCurrentDomain } from '@/utils/getCurrentDomain';
import { useNavigate } from 'react-router-dom';
import Typography from '@shared/components/Typography';
import { useTranslation } from 'react-i18next';
import Button from '@shared/components/Button';
import { ArrowUpIcon, DownloadIcon, CheckIcon, AlertCircleIcon } from '@shared/assets/images/icons';
import clsx from 'clsx';
import LazyTable, { IColumn } from '@components/LazyTable';
import Flex from '@shared/components/Flex';
import BodyContainer from '@/layouts/BodyContainer';
import { DomainStatus } from '@components/typings/interfaces';
import { DocumentModel } from '@/client/generated/graphql';
import { globalNotification$ } from '@components/GlobalSnackbarNotification';
import downloadFileSafe from '@shared/utils/downloadFileSafe';
import { stepsNamesList } from '../Contract/Contract.constants';
import {
  DocumentsActionCell,
  DocumentsDateCell,
  DocumentsNumberCell,
  DocumentsTypeCell,
} from './modules';
import { useDocumentsStyle } from './DocumentsList.styles';

export const columns: IColumn<DocumentModel>[] = [
  { id: 'type', verticalAlign: 'center', label: 'DOCUMENTS', Renderer: DocumentsTypeCell },
  { id: 'number', verticalAlign: 'center', label: 'NUMBER', Renderer: DocumentsNumberCell },
  { id: 'signingAt', verticalAlign: 'center', label: 'DATE', Renderer: DocumentsDateCell },
  {
    id: 'downloadUrl',
    align: 'right',
    verticalAlign: 'center',
    label: '',
    Renderer: DocumentsActionCell,
  },
];
// TODO (fix types) where are the docs?
export const DocumentsList = () => {
  const classes = useDocumentsStyle();
  const { data: userData } = useQuery(USER_QUERY, { fetchPolicy: 'no-cache' });
  const { data: { getDocuments: documentList = [] } = {}, loading } = useQuery(GET_DOCUMENTS_QUERY);
  const [translate] = useTranslation();
  const navigate = useNavigate();
  const [isContractDownloading, setIsContractDownloading] = useState(false);
  const status = useMemo(() => getCurrentDomain(userData?.user)?.status, [userData]);
  const docsFlags = userData?.user.domains.find((domain) => domain.current)?.params?.docs;

  const currentStep = useMemo(() => {
    if (!docsFlags) {
      return null;
    }
    if (docsFlags?.assistants) {
      return 5;
    }
    if (docsFlags?.numbers) {
      return 4;
    }
    if (docsFlags?.tariff) {
      return 3;
    }
    if (docsFlags?.passport) {
      return 2;
    }
    return 1;
  }, [docsFlags]);

  const renderSteps = useMemo(
    () =>
      stepsNamesList.map((item, ind) => {
        const index = ind + 1;
        const isNotLastStep = item.key !== 'check';
        const isDataFilled = docsFlags && !!docsFlags[item.key as keyof typeof docsFlags];
        const lastStep = currentStep === 5;

        const renderIcon = () => {
          if (isDataFilled && isNotLastStep) {
            return <CheckIcon className={classes.checkIcon} />;
          }
          if (!isNotLastStep && lastStep) {
            return <AlertCircleIcon />;
          }
          return (
            <Typography type={'text3'} color={'tertiary900'}>
              {index}
            </Typography>
          );
        };

        return (
          <div key={index} className={classes.stepItem}>
            <div
              className={clsx(classes.stepIcon, {
                [classes.stepIconCurrentBg]: !isNotLastStep || lastStep,
                [classes.stepIconSuccessBg]: isDataFilled,
                [classes.stepIconLastBg]: !isNotLastStep && !lastStep,
              })}
            >
              {renderIcon()}
            </div>
            <Typography
              type={'text3'}
              color={index !== 5 || lastStep ? 'tertiary900' : 'tertiary400'}
              underline={isNotLastStep}
            >
              {translate(item.title)}
            </Typography>
          </div>
        );
      }),
    [currentStep, classes, translate, docsFlags]
  );

  const handleButtonDownloadContractClick = async () => {
    if (isContractDownloading) {
      return;
    }

    setIsContractDownloading(true);

    const result = await downloadFileSafe('/Договор оферты.pdf', 'Договор оферты.pdf');
    if (result.type === 'error') {
      globalNotification$.show('danger', translate('COULD_NOT_DOWNLOAD_FILE'));
    }

    setIsContractDownloading(false);
  };

  const renderButtonDownloadContract = () => {
    return (
      <Button
        className={classes.buttonDownloadContract}
        loading={isContractDownloading}
        variant="text"
        onClick={handleButtonDownloadContractClick}
        startIcon={isContractDownloading ? undefined : <DownloadIcon />}
      >
        <Typography type={'text3'} color={'tertiary900'} underline>
          {translate('DOWNLOAD_CONTRACT_TEMPLATE')}
        </Typography>
      </Button>
    );
  };

  const renderDocumentsTable = () => (
    <>
      <Breadcrumbs />
      <LazyTable<DocumentModel>
        columns={columns}
        data={documentList}
        pending={loading}
        emptyDataMessage={translate(!loading ? 'DOCUMENTS_NOT_FOUND' : '')}
      />
    </>
  );

  const renderDidNotStartReg = () => (
    <div className={classes.container}>
      <Typography>{translate('CONTRACT_DESCRIPTION')}</Typography>
      <ArrowUpIcon className={classes.menuArrowDown} />
      <Button
        title={translate('EXECUTE_CONTRACT')}
        className={classes.defaultElementWidth17}
        onClick={() => {
          navigate('/register/contract');
        }}
      />
      {renderButtonDownloadContract()}
    </div>
  );

  const renderStartedReg = () => (
    <div className={classes.container}>
      <Typography type={'text3'} color={'tertiary900'}>
        {translate('DOCUMENTS_SCALE_STEPS_TEXT')}
      </Typography>
      <ArrowUpIcon className={classes.menuArrowDown} />
      <div className={classes.stepsRow}>{renderSteps}</div>
      <Button
        title={translate('CONTINUE_CONTRACT')}
        className={classes.defaultElementWidth17}
        onClick={() => {
          navigate('/register/contract');
        }}
      />
      {renderButtonDownloadContract()}
    </div>
  );

  const renderStatusContent = () => {
    if (status !== DomainStatus.Demo) {
      return renderDocumentsTable();
    }
    if (currentStep === 1) {
      renderDidNotStartReg();
    }
    return renderStartedReg();
  };

  return (
    <BodyContainer disableOverflow>
      <Flex direction={'column'} className={classes.root}>
        {renderStatusContent()}
      </Flex>
    </BodyContainer>
  );
};
