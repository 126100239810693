import { scenarioSettingsType, scenariosListType } from '../IntegrationForm.interfaces';
import SimpleCallsHelp01 from './images/simple_calls_help_01.png';
import SimpleCallsHelp02 from './images/simple_calls_help_02.png';

export const SimpleCallsScenarioInformationTabs: scenariosListType[] = [
  [
    {
      title: 'OPERATIONS_WITH_NUMBERS',
      active: true,
      options: ['SCENARIO_NUMBERS_DESCRIPTION'],
    },
  ],
  [
    {
      title: 'SCENARIO_TITLE_01',
      edit: scenarioSettingsType.Always,
      options: ['SCENARIO_DESCRIPTION_01'],
    },
    {
      title: 'SCENARIO_TITLE_02',
      edit: scenarioSettingsType.Always,
      options: ['SCENARIO_DESCRIPTION_02'],
    },
    {
      title: 'SCENARIO_TITLE_03',
      edit: scenarioSettingsType.Always,
      options: ['SCENARIO_DESCRIPTION_03'],
    },
    {
      title: 'SCENARIO_TITLE_04',
      edit: scenarioSettingsType.Always,
      options: ['SCENARIO_DESCRIPTION_04'],
    },
    {
      title: 'INCOMING_SCENARIOS_TITLE_04',
      edit: scenarioSettingsType.Responsible,
      options: ['INCOMING_SCENARIOS_ITEM_04'],
    },
  ],
];

export const SIMPLE_CALLS_SLIDER_IMAGES = [
  { title: 'WHERE_SEARCH_SIMPLE_CALLS_MESSAGE', img: SimpleCallsHelp01 },
  { title: 'WHERE_SEARCH_SIMPLE_CALLS_MESSAGE_02', img: SimpleCallsHelp02 },
];
