import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { CREATE_EMPLOYEE_MUTATION } from '@/client/mutations';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import PhoneField from '@shared/components/PhoneField';
import { normalizePhone } from '@shared/utils';
import {
  CHECK_IF_EMAIL_EXIST_LOCAL_QUERY,
  GET_USED_EXTENSIONS_QUERY,
  GET_USER_NAME_BY_EMAIL_QUERY,
  USER_QUERY,
} from '@/client/queries';
import { AlertCircleIcon } from '@shared/assets/images/icons';
import Tooltip from '@shared/components/Popover';
import validator from 'validator';
import { debounce } from '@material-ui/core';
import { usePrevious } from '@/common/hooks/usePrev.hooks';
import CallForwardingFields from '@components/CallForwadingFields';
import { useFormErrors } from '@components/common/formErrors.hooks';
import SelectField from '@shared/components/SelectField';
import { employeeRolesOptions } from '@/features/Employees/EmployeesConstants';
import FormErrorMessage from '@components/FormErrorMessage';
import PasswordField from '@shared/components/PasswordField';
import Typography from '@shared/components/Typography';
import EmailField from '@shared/components/EmailField';
import { getCurrentEmployee } from '@/utils/getCurrentEmployee';
import { getCurrentDomain } from '@/utils/getCurrentDomain';
import ConfirmDialog, { ConfirmAction, IConfirmState } from '@components/ConfirmDialog';
import {
  getAllowedEmergenceNumbersStatus,
  getExtLength,
  getFreeExtensions,
  getRole,
  hasSystemRole,
} from '@/utils';
import ControlButtons from '@shared/components/ControlButtons';
import BodyContainer from '@/layouts/BodyContainer';
import BottomButtons from '@/layouts/BottomButtons';
import FormFieldRhfUncontrolled from '@shared/components/FormFieldRhfUncontrolled';
import ComboBoxField from '@shared/components/ComboBoxField';
import { Role } from '@/client/generated/graphql';
import { globalNotification$ } from '@components/GlobalSnackbarNotification';
import * as Sentry from '@sentry/react';
import { useCatchAbleLazyQuery } from '@shared/utils/apollo';
import { useEmployeeStyles } from './Employee.styles';

type FormData = {
  id: number;
  name: string;
  email: string;
  phone: string;
  language: string;
  password: string;
  ext: string;
  role: string;

  sipPassword?: string;
  position?: string;
  callForwarding?: boolean;
  callForwardingTimeout?: number;
};

const navigateTimeoutMs = 150;

export const CreateEmployee = () => {
  const classes = useEmployeeStyles();
  const [translate] = useTranslation();
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [usingExistingData, setUsingExistingData] = useState<string | undefined>();
  const [blockedPath, setBlockedPath] = useState<string | null>(null);
  const [checkingEmail, setCheckingEmail] = useState<string | undefined>();
  const [isChanged, setIsChanged] = useState<IConfirmState>({
    isBlocking: false,
    action: ConfirmAction.Edit,
  });

  const [createEmployee, { error, called, loading }] = useMutation(CREATE_EMPLOYEE_MUTATION);

  const { data } = useQuery(USER_QUERY, { fetchPolicy: 'cache-first' });
  const user = data?.user;
  const currentEmployee = getCurrentEmployee(user);
  const currentEmployeeRole = currentEmployee?.role;
  const userDomain = user && getCurrentDomain(user)?.domain;

  const isEmergencyServicesAllowed = getAllowedEmergenceNumbersStatus();
  const extLength = getExtLength();

  const formMethods = useForm<FormData>();
  useFormErrors(error?.graphQLErrors, formMethods);
  const { handleSubmit, watch, setError, clearErrors, setValue, trigger } = formMethods;
  const callForwardingEnabled = watch('callForwarding');
  const email = watch('email') || '';
  const ext = watch('ext');
  const isEmailNotificationShow = useMemo(
    () => userDomain && email?.includes(userDomain),
    [email, userDomain]
  );
  const isDomainEmail = useMemo(
    () => email && email?.slice(email.lastIndexOf('@') + 1) === userDomain,
    [email, userDomain]
  );

  const [
    checkIfEmailExist,
    {
      data: checkIfEmailExistData,
      loading: checkIfEmailExistLoading,
      error: { graphQLErrors: checkIfEmailExistErrors = [] } = {},
    },
  ] = useLazyQuery(CHECK_IF_EMAIL_EXIST_LOCAL_QUERY, { fetchPolicy: 'no-cache' });
  const prevCheckIfEmailExistLoading = usePrevious(checkIfEmailExistLoading);

  const { isEmailExistLocal: { exist = undefined, local = undefined } = {} } =
    checkIfEmailExistData || {};

  useEffect(() => {
    if (!checkIfEmailExistLoading && checkIfEmailExistLoading !== prevCheckIfEmailExistLoading) {
      if (exist && email === checkingEmail) {
        if (!local) {
          setOpen(true);
        }
        setError('email', { type: 'error', message: translate('EMAIL_ALREADY_REGISTERED') });
      }
    }
  }, [
    checkIfEmailExistLoading,
    checkingEmail,
    email,
    exist,
    local,
    prevCheckIfEmailExistLoading,
    setError,
    translate,
  ]);

  const [getUserNameByEmail] = useCatchAbleLazyQuery(GET_USER_NAME_BY_EMAIL_QUERY);

  const {
    data: extensionData,
    loading: loadingExtensions,
    error: loadingExtensionsError,
  } = useQuery<{
    getUsedExtensions?: {
      ext: string;
    }[];
  }>(GET_USED_EXTENSIONS_QUERY);
  const { getUsedExtensions: extensions = undefined } = extensionData || {
    getUsedExtensions: undefined,
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedCheckIfEmailExist = useCallback(
    debounce((value: string) => {
      clearErrors('email');
      setOpen(false);
      checkIfEmailExist({ variables: { email: value } });
      setCheckingEmail(value);
    }, 300),
    []
  );

  const extChange = useCallback(
    (value?: string) => {
      const computedExt = (value || ext || '').slice(0, extLength);
      if (((userDomain && isDomainEmail) || !email.length) && !called && !loading)
        setValue('email', `${computedExt}@${userDomain}`);
    },
    [called, email, ext, extLength, isDomainEmail, loading, setValue, userDomain]
  );

  useEffect(() => {
    if (ext && userDomain && !isChanged.isBlocking && !called && !loading)
      setValue('email', `${ext}@${userDomain}`);
  }, [called, ext, isChanged.isBlocking, loading, setValue, userDomain]);

  useEffect(() => {
    if (email && validator.isEmail(email)) {
      debouncedCheckIfEmailExist(email);
    }
  }, [debouncedCheckIfEmailExist, email]);

  const handleFormSubmit: SubmitHandler<FormData> = (fields) => {
    if (loading) {
      return;
    }

    const shouldOpenUseExistingDataTooltip =
      exist &&
      !local &&
      email === checkingEmail &&
      usingExistingData === undefined &&
      !checkIfEmailExistLoading;

    if (shouldOpenUseExistingDataTooltip) {
      setOpen(true);
      return;
    }

    if (usingExistingData) {
      // eslint-disable-next-line no-param-reassign
      fields.password = '';
    }

    const role = getRole(fields.role);
    if (!role) {
      Sentry.captureException(
        Error(`Trying to create employee in CreateEmployee, but role is incorrect. Got: ${role}.`)
      );
      setError('role', { message: translate('SOMETHING_WENT_WRONG') });
      return;
    }

    createEmployee({
      variables: {
        data: {
          ext: fields.ext,
          role,

          user: {
            email: fields.email,
            password: fields.password,
            name: fields.name,
          },

          // optional
          callForwarding: fields.callForwarding,
          callForwardingTimeout: fields.callForwardingTimeout,
          sipPassword: fields.sipPassword || undefined,
          position: fields.position || undefined,
          phone: normalizePhone(fields.phone) || undefined,
        },
      },
    })
      .then(() => {
        setIsChanged({ action: ConfirmAction.Finish, isBlocking: false });
        setTimeout(() => {
          navigate(blockedPath || '/employee/employees');
        }, navigateTimeoutMs);
      })
      .catch((err) => {
        Sentry.captureException(Error(`Error creating employee in CreateEmployee: ${err}`));
        globalNotification$.show('danger', translate('SOMETHING_WENT_WRONG'));
      });
  };

  const useExistingData = () => {
    clearErrors(['email', 'name', 'password']);
    setValue('password', '');
    getUserNameByEmail({ variables: { email } })
      .then((dataObj) => {
        const existingName = dataObj.data?.getUserNameByEmail;

        if (existingName) {
          setValue('name', existingName);
          setValue('password', '!@#$%^&*(Aa01)');
        }

        setUsingExistingData(email);
      })
      .catch((err) => {
        Sentry.captureException(
          Error(`Cannot get user name by email in CreateEmployee, error: ${err}`)
        );
        globalNotification$.show('danger', translate('SOMETHING_WENT_WRONG'));
      });
  };

  const { extensionsOptions = [], defaultExt = '' } =
    loadingExtensions || loadingExtensionsError || !extensions
      ? { extensionsOptions: [], defaultExt: '' }
      : getFreeExtensions(extensions, isEmergencyServicesAllowed, extLength);

  useEffect(() => {
    setValue('ext', defaultExt);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultExt]);

  const EmailAlreadyExistTooltip = () => {
    if (
      email === checkingEmail &&
      usingExistingData === undefined &&
      exist &&
      !local &&
      !checkIfEmailExistLoading &&
      !checkIfEmailExistErrors.length
    ) {
      return (
        <Tooltip
          placement={'top-end'}
          open={open}
          onClose={() => setOpen(false)}
          PopperProps={{
            disablePortal: true,
            popperOptions: {
              modifiers: {
                offset: {
                  enabled: true,
                  offset: '25, 5',
                },
              },
            },
          }}
          title={
            <div className={classes.tooltipContent}>
              <Typography type={'text4'} color={'tertiary900'}>
                {translate('EMAIL_ALREADY_REGISTERED_HINT_01')}
              </Typography>
              <span className={classes.tooltipLink} onClick={useExistingData}>
                <Typography type={'text4'} color={'primary700'} underline>
                  {translate('EMAIL_ALREADY_REGISTERED_HINT_02', { email })}
                </Typography>
              </span>
            </div>
          }
        >
          <AlertCircleIcon onMouseOver={() => setOpen(true)} />
        </Tooltip>
      );
    }
    return null;
  };

  const getEmailFieldInputProps = () => {
    if (usingExistingData) {
      return {
        readOnly: true,
      };
    }
    if (
      !usingExistingData &&
      exist &&
      !local &&
      !checkIfEmailExistLoading &&
      !checkIfEmailExistErrors.length
    ) {
      return {
        endAdornment: EmailAlreadyExistTooltip(),
      };
    }
    return undefined;
  };

  const formErrors = [
    ...(error?.graphQLErrors || []),
    ...(loadingExtensionsError?.graphQLErrors || []),
  ];

  let availableRoles = [...employeeRolesOptions];
  if (currentEmployeeRole === Role.Admin) {
    availableRoles = availableRoles.filter((r) => r.value !== Role.Owner);
  }

  function getBlockedPath(path: string) {
    setBlockedPath(path);
  }

  function handleFormChange() {
    setIsChanged({
      isBlocking: true,
      action: ConfirmAction.Edit,
    });
  }

  function handleEmailChange(value: string) {
    trigger('email').then();
    if (value.slice(value.lastIndexOf('@') + 1) === userDomain) {
      setValue('email', `${ext}@${userDomain}`);
    }
  }

  function handleExtChange(value: string) {
    handleFormChange();
    extChange(value);
  }

  function handleCancelChanges() {
    setIsChanged({
      isBlocking: false,
      action: ConfirmAction.Cancel,
    });
    setTimeout(() => {
      navigate('/employee/employees');
    }, navigateTimeoutMs);
  }

  return (
    <BodyContainer customRootClass={classes.contentBottomSpace}>
      <div className={classes.root}>
        <FormProvider {...formMethods}>
          <form
            id={'create-employee-form'}
            action={''}
            onSubmit={handleSubmit(handleFormSubmit)}
            className={classes.form}
            noValidate
          >
            <Typography type={'text2'} color={'tertiary900'}>
              {translate('EMPLOYEE_DATA')}
            </Typography>
            <div className={classes.formContent}>
              <FormFieldRhfUncontrolled
                name={'name'}
                label={translate('NAME')}
                inputProps={{
                  maxLength: 100,
                  readOnly: !!usingExistingData,
                }}
                validate={(value: string) =>
                  value === '' ? (translate('ENTER_NAME') as string) : true
                }
                onChange={handleFormChange}
              />
              <EmailField
                name={'email'}
                label={translate('EMAIL_LOGIN')}
                required={!usingExistingData}
                InputProps={getEmailFieldInputProps()}
                validate={(value: string) =>
                  value && local ? (translate('EMAIL_ALREADY_REGISTERED') as string) : true
                }
                onChange={(e) => handleEmailChange(e.target.value)}
                onFocus={() => setOpen(true)}
              />
              {isEmailNotificationShow && (
                <div className={classes.notificationBlock}>
                  <Typography type={'text4'} color={'tertiary900'}>
                    {translate('EMAIL_GENERATE_AUTOMATICALLY')}
                  </Typography>
                </div>
              )}
              <div className={classes.itemWrapper}>
                <PasswordField
                  name={'password'}
                  label={translate('PASSWORD')}
                  scoring={!usingExistingData}
                  generation={!usingExistingData}
                  required={!usingExistingData}
                  InputProps={{ readOnly: !!usingExistingData }}
                  onChange={handleFormChange}
                />
              </div>
              {hasSystemRole(currentEmployeeRole) && (
                <div className={classes.itemWrapper}>
                  <PasswordField
                    name={'sipPassword'}
                    label={translate('SIP_PASSWORD')}
                    scoring
                    generation
                    onChange={handleFormChange}
                  />
                </div>
              )}
              <SelectField
                name={'role'}
                valueKey={'value'}
                titleKey={'titleCode'}
                defaultValue={availableRoles[1].value}
                data={availableRoles}
                translating
                label={translate('ROLE')}
                onChange={handleFormChange}
              />
              <ComboBoxField
                name="ext"
                valueKey="ext"
                freeSolo
                maxLength={extLength}
                showOptionsIfEmpty={false}
                data={extensionsOptions}
                placeholder={translate('CHOOSE')}
                label={translate('EXTENSION_NUMBER')}
                onChange={(_e, value) => {
                  if (typeof value === 'string') {
                    handleExtChange(value);
                  } else if (typeof value === 'object') {
                    handleExtChange(value.ext);
                  }
                }}
                onInputChange={(_e, val, reason) => {
                  if (reason === 'reset') return;
                  handleExtChange(val);
                }}
                validate={(extValue: string) => {
                  const value = Number(extValue);
                  if (!extValue) {
                    return translate('EXTENSION_NUMBER_REQUIRED') as string;
                  }
                  if (extValue.length !== extLength) {
                    return translate('EXTENSION_NUMBER_WRONG_LENGTH', { length: extLength });
                  }
                  if (Number.isNaN(value)) {
                    return translate('INVALID_EXTENSION');
                  }
                  if (value >= 100 && value <= 199 && !isEmergencyServicesAllowed) {
                    return translate('EXTENSION_NUMBER_FOR_EMERGENCY') as string;
                  }
                  return true;
                }}
                filterOptions={(options, { inputValue }) => {
                  return options.filter((option) => option.ext.startsWith(inputValue));
                }}
              />
              <FormFieldRhfUncontrolled
                name={'position'}
                label={translate('POSITION')}
                onChange={handleFormChange}
                inputProps={{
                  maxLength: 100,
                }}
              />
              <PhoneField
                name={'phone'}
                label={translate('MOBILE')}
                onChange={handleFormChange}
                required={callForwardingEnabled}
              />
              <div className={classes.formContentWrapper}>
                <Typography type={'text2'} color={'tertiary900'} className={classes.title}>
                  {translate('REDIRECT_TO_DEVICES')}
                </Typography>
                <CallForwardingFields
                  onChange={handleFormChange}
                  className={classes.defaultElementWidth20}
                />
              </div>
            </div>
            <BottomButtons>
              <div className={classes.actions}>
                <ControlButtons
                  confirmTitle={'SAVE_CHANGES'}
                  cancelTitle={'CANCEL'}
                  form={'create-employee-form'}
                  onCancelClick={handleCancelChanges}
                  rootConfirmStyles={classes.controlButton}
                  loading={loading}
                />
              </div>
            </BottomButtons>
            <FormErrorMessage errors={formErrors} />
          </form>
          <ConfirmDialog
            isBlocked={isChanged.isBlocking}
            onNavigationBlocked={getBlockedPath}
            onSaveChanges={handleSubmit(handleFormSubmit)}
          />
        </FormProvider>
      </div>
    </BodyContainer>
  );
};

export default CreateEmployee;
