import { debounce, IconButton, InputAdornment } from '@material-ui/core';
import FormFieldRhfUncontrolled from '@shared/components/FormFieldRhfUncontrolled';
import React, { ChangeEvent, FunctionComponent, useEffect, useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { SearchIcon, XIcon } from '@shared/assets/images/icons';
import clsx from 'clsx';
import { useQuickSearchStyles } from './QuickSearch.styles';

export const QuickSearch: FunctionComponent<{
  parameterName: string;
  color?: 'primary' | 'secondary';
  disabled?: boolean;
  className?: string;
}> = ({ parameterName = 'query', color = 'primary', disabled = false, className }) => {
  const formMethods = useFormContext();
  const classes = useQuickSearchStyles();
  const [translate] = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const query = searchParams.get(parameterName) || '';

  // Those two refs are used to prevent setting stale value
  // that comes from searchParams changing. Detailed description - https://gravitel.atlassian.net/browse/AIC-1820?focusedCommentId=13297
  const refLastValueSetWithSearchChange = useRef<string | null>(null);
  const refTimeoutRemoveLastValueSetWithSearchChange = useRef<ReturnType<typeof setTimeout> | null>(
    null
  );

  function modifySearchParams(searchValue: string) {
    if (searchValue) {
      searchParams.set(parameterName, searchValue);
    } else {
      searchParams.delete(parameterName);
    }
    setSearchParams(searchParams);
  }

  const handleSearchChange = debounce((event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    if (query === value) {
      return;
    }

    refLastValueSetWithSearchChange.current = value;
    if (refTimeoutRemoveLastValueSetWithSearchChange.current) {
      clearTimeout(refTimeoutRemoveLastValueSetWithSearchChange.current);
    }
    refTimeoutRemoveLastValueSetWithSearchChange.current = setTimeout(() => {
      refLastValueSetWithSearchChange.current = null;
      refTimeoutRemoveLastValueSetWithSearchChange.current = null;
    }, 1000);

    modifySearchParams(value);
  }, 1000);

  function handleSearchClear() {
    searchParams.delete(parameterName);
    setSearchParams(searchParams);
    formMethods.setValue(parameterName, '');
  }

  useEffect(() => {
    if (
      formMethods.getValues()[parameterName] === query ||
      query === refLastValueSetWithSearchChange.current
    ) {
      return;
    }

    formMethods.setValue(parameterName, query || '');
  }, [query, formMethods, parameterName]);

  useEffect(() => {
    const cleanup = () => {
      if (refTimeoutRemoveLastValueSetWithSearchChange.current) {
        clearTimeout(refTimeoutRemoveLastValueSetWithSearchChange.current);
      }
    };
    return cleanup;
  }, []);

  const endAdornment = (
    <InputAdornment position={'end'}>
      <IconButton
        edge={'end'}
        color={'inherit'}
        size={'small'}
        disabled={disabled}
        onClick={handleSearchClear}
      >
        {query.length ? <XIcon /> : <SearchIcon />}
      </IconButton>
    </InputAdornment>
  );

  return (
    <FormFieldRhfUncontrolled
      name={parameterName}
      color={color}
      placeholder={translate('SEARCH')}
      onChange={handleSearchChange}
      defaultValue={query}
      disabled={disabled}
      classes={{ root: clsx(classes.root, className) }}
      InputProps={{
        endAdornment,
        classes: { inputMarginDense: classes.inputMarginDense },
      }}
    />
  );
};

export default QuickSearch;
