import { makeStyles } from '@material-ui/core';
import { FilterIcon, XIcon } from '@shared/assets/images/icons';
import Button from '@shared/components/Button';
import clsx from 'clsx';
import React from 'react';

type FilterButtonsProps = {
  isFilterApplied: boolean;
  onFilterButtonClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onCancelButtonClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
};

const useFilterButtonsStyles = makeStyles(({ color: { primary, tertiary } }) => ({
  buttonFilter: {
    padding: 0,
    marginTop: '-1px',
    '&:hover svg': {
      color: primary[700],
    },
  },
  buttonCancel: {
    padding: 0,
    border: 0,
    marginTop: '-1px',
  },
  colorPrimary: {
    color: primary[700],
  },
  colorTertiary: {
    color: tertiary[500],
  },
}));

const FilterButtons = ({
  isFilterApplied,
  onFilterButtonClick,
  onCancelButtonClick,
}: FilterButtonsProps) => {
  const classes = useFilterButtonsStyles();

  return (
    <>
      {isFilterApplied && (
        <Button className={classes.buttonCancel} variant="text" onClick={onCancelButtonClick}>
          <XIcon className={classes.colorTertiary} />
        </Button>
      )}

      <Button
        className={classes.buttonFilter}
        variant="text"
        onClick={onFilterButtonClick}
        disableFocusRipple
      >
        <FilterIcon
          className={clsx(isFilterApplied ? classes.colorPrimary : classes.colorTertiary)}
        />
      </Button>
    </>
  );
};

export default FilterButtons;
