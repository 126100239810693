import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation, useQuery } from '@apollo/client';
import { GET_BLACK_LIST_QUERY } from '@/client/queries';
import { ADD_TO_BLACKLIST_MUTATION } from '@/client/mutations';
import Typography from '@shared/components/Typography';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import QuickSearch from '@components/QuickSearch';
import Button from '@shared/components/Button';
import { PhoneOffIcon } from '@shared/assets/images/icons';
import MessageDialog from '@shared/components/MessageDialog';
import FormFieldRhfUncontrolled from '@shared/components/FormFieldRhfUncontrolled';
import { normalizePhone } from '@shared/utils';
import PageError from '@shared/components/PageError';
import PhoneField from '@shared/components/PhoneField';
import ControlButtons from '@shared/components/ControlButtons';
import LazyTable, { ContentHint, IColumn, CellPreloader } from '@components/LazyTable';
import Flex from '@shared/components/Flex';
import BodyContainer from '@/layouts/BodyContainer';
import { ErrorDialog, useErrorDialog } from '@components/ErrorDialog';
import { getErrorMessageFromGraphqlError } from '@shared/utils/apollo';
import { useSettingsBlackListStyles } from './SettingsBlackList.styles';
import { IBlackList } from './SettingsBlackList.interfaces';
import {
  BlackListActionsCell,
  BlackListNumberCell,
  BlackListCallsPerMonthCell,
  BlackListCallsPerYearCell,
  BlackListCommentCell,
} from './modules';

export const blackListColumns: IColumn<IBlackList>[] = [
  {
    id: 'number',
    verticalAlign: 'center',
    label: 'NUMBER',
    minWidth: '15%',
    Renderer: BlackListNumberCell,
  },
  {
    id: 'comment',
    verticalAlign: 'center',
    label: 'COMMENTS',
    minWidth: '40%',
    Renderer: BlackListCommentCell,
  },
  { id: 'callsPer', verticalAlign: 'center', label: 'CALLS_PER' },
  {
    id: 'cellPerYear',
    verticalAlign: 'center',
    label: 'YEAR',
    Renderer: BlackListCallsPerYearCell,
  },
  {
    id: 'cellPerMonth',
    verticalAlign: 'center',
    label: 'MONTH',
    Renderer: BlackListCallsPerMonthCell,
  },
  {
    id: 'cellActions',
    label: '',
    verticalAlign: 'center',
    align: 'right',
    Renderer: BlackListActionsCell,
  },
];

interface IFormFields {
  blackListPhone: string;
  comment: string;
  searchText: string | null;
}

export const SettingsBlackList: FunctionComponent = () => {
  const [translate] = useTranslation();
  const classes = useSettingsBlackListStyles();
  const [searchParams, setSearchParams] = useSearchParams();
  const { showErrorDialog, handleShowErrorDialog, handleCloseErrorDialog } = useErrorDialog();
  const [isAddNumberDialogOpen, setIsAddNumberDialogOpen] = useState(false);
  const sSearchText = searchParams.get('searchText') || null;
  const [hasData, setHasData] = useState(false);

  const formMethods = useForm<IFormFields>({
    defaultValues: {
      searchText: sSearchText,
    },
  });
  const { handleSubmit, setValue } = formMethods;

  const {
    data: blackListData,
    loading: blacklistLoading,
    error: blacklistError,
    refetch,
  } = useQuery(GET_BLACK_LIST_QUERY, {
    variables: {
      data: {
        search: sSearchText,
      },
    },
  });

  const [addToBlackList, { loading: addToBlackListLoading }] = useMutation(
    ADD_TO_BLACKLIST_MUTATION,
    {
      refetchQueries: [
        {
          query: GET_BLACK_LIST_QUERY,
          variables: {
            data: {
              search: sSearchText,
            },
          },
        },
      ],
      onCompleted: () => {
        setIsAddNumberDialogOpen(false);
        searchParams.delete('searchText');
        setSearchParams(searchParams);
        refetch({
          data: {
            search: sSearchText,
          },
        });
      },
    }
  );

  const { total, rows } = useMemo(() => {
    if (!blackListData) return { total: 0, rows: [] };
    return {
      total: blackListData?.getBlackList?.total,
      rows: blackListData?.getBlackList?.rows || [],
    };
  }, [blackListData]);

  function handleAddToBlackList(formData: IFormFields) {
    const { blackListPhone, comment = '' } = formData;
    addToBlackList({
      variables: {
        data: {
          phone: normalizePhone(blackListPhone),
          comment,
        },
      },
    }).catch((error) => {
      setIsAddNumberDialogOpen(false);
      let titleText = 'SOMETHING_WENT_WRONG';
      let messageText = 'SOMETHING_WRONG_MESSAGE';
      if (getErrorMessageFromGraphqlError(error) === 'PHONE_IS_NOT_UNIQUE') {
        titleText = 'ERROR';
        messageText = 'PHONE_IS_NOT_UNIQUE';
      }
      handleShowErrorDialog({ titleText, messageText });
    });
  }

  useEffect(() => {
    refetch({
      data: {
        search: sSearchText,
      },
    });
  }, [refetch, sSearchText]);

  useEffect(() => {
    if (total || sSearchText) {
      setHasData(true);
    }
  }, [total, sSearchText]);

  function handleOpenAddNumberDialog() {
    setValue('blackListPhone', '');
    setValue('comment', '');
    setIsAddNumberDialogOpen(true);
  }

  if (blacklistError) {
    return (
      <div className={classes.errorPageWrap}>
        <PageError />
      </div>
    );
  }

  const renderEmptyContent = () => {
    if (!blacklistLoading) {
      if (!hasData || !rows.length) {
        return (
          <ContentHint isContentCenter contentWidth={14}>
            <PhoneOffIcon className={classes.phoneIcon} />
            <Typography color={'tertiary600'} type={'text3'}>
              {translate('NUMBERS_IN_BLACKLIST')}
            </Typography>
          </ContentHint>
        );
      }
      if (!rows.length && sSearchText) {
        return (
          <CellPreloader>
            <Typography color={'tertiary900'} type={'text3'}>
              {translate('NOTHING_FOUND')}
            </Typography>
          </CellPreloader>
        );
      }
    }
    if (blacklistLoading) {
      return <CellPreloader />;
    }
    return null;
  };

  const renderAddNumberBlackList = () => {
    if (isAddNumberDialogOpen) {
      return (
        <MessageDialog
          contentClass={classes.defaultElementWidth25}
          title={translate('NUMBER_DATA')}
          isOpen
          onCancel={() => setIsAddNumberDialogOpen(false)}
          renderContent={
            <form onSubmit={handleSubmit(handleAddToBlackList)} id={'block-number'}>
              <div className={classes.addBlackListContent}>
                <PhoneField name={'blackListPhone'} label={translate('NUMBER')} required />
                <FormFieldRhfUncontrolled
                  className={classes.formField}
                  name={'comment'}
                  label={translate('COMMENT')}
                  fullWidth
                  rows={2}
                  multiline
                  inputProps={{
                    maxLength: 90,
                  }}
                />
              </div>
              <div className={classes.addNumberActions}>
                <ControlButtons
                  confirmTitle={'ADD_IN_LIST'}
                  cancelTitle={'CANCEL'}
                  cancelVariant="secondary"
                  form={'block-number'}
                  flexDirection={'row-reverse'}
                  justifyContent={'start'}
                  onCancelClick={() => setIsAddNumberDialogOpen(false)}
                  loading={addToBlackListLoading}
                  small
                />
              </div>
            </form>
          }
        />
      );
    }
    return null;
  };

  return (
    <FormProvider {...formMethods}>
      <BodyContainer disableOverflow>
        <Flex direction={'column'} className={classes.root}>
          <div className={classes.headerContainer}>
            <Typography color={'tertiary900'} type={'text2'}>
              {translate('BLACK_LIST')}
            </Typography>
            <div>
              <QuickSearch parameterName={'searchText'} />
              <Button
                title={translate('ADD_NUMBER')}
                className={classes.action}
                onClick={() => handleOpenAddNumberDialog()}
              />
            </div>
          </div>
          <LazyTable<IBlackList>
            columns={blackListColumns}
            data={rows}
            renderEmptyDataMessage={renderEmptyContent()}
          />
          {renderAddNumberBlackList()}
        </Flex>
        <ErrorDialog
          showErrorDialog={showErrorDialog}
          handleCloseErrorDialog={handleCloseErrorDialog}
        />
      </BodyContainer>
    </FormProvider>
  );
};
