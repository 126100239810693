import React, { ChangeEvent, FunctionComponent, useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import BodyContainer from '@/layouts/BodyContainer';
import FilterByNumbers from '@/components/FilterByNumbers';
import Flex from '@shared/components/Flex';
import SelectField, { ISelectFieldOptionRendererProps } from '@shared/components/SelectField';
import Breadcrumbs from '@/components/Breadcrumbs';
import {
  LineChartIcon,
  ExcelExportIcon,
  XIcon,
  CheckIcon,
  BarChartIcon,
  StatisticsIcon,
  SearchIcon,
  EyeOffIcon,
  EyeIcon,
} from '@shared/assets/images/icons';
import clsx from 'clsx';
import Typography from '@shared/components/Typography';
import { getDateList, isArraysValuesEqual } from '@/utils';
import { Button } from '@shared/components/Button/Button';
import { getStorageItem } from '@components/storage/storage';
import { BarDiagram, IDatasetsData, LineDiagram } from '@components/Diagram';
import {
  Chart,
  FontSpec,
  InteractionItem,
  InteractionMode,
  ScriptableTooltipContext,
  TooltipItem,
  TooltipPositioner,
} from 'chart.js';
import { formatTimeHour } from '@components/utils';
import { useLazyQuery, useQuery } from '@apollo/client';
import {
  DOMAIN_NUMBERS_QUERY,
  GET_NUMBERS_STATISTICS_QUERY,
  HAS_HISTORY_CALLS_QUERY,
} from '@/client/queries';
import { IFilterChangeValues } from '@/components/FilterByNumbers/FilterByNumbers.interfaces';
import PageError from '@shared/components/PageError';
import StaticDatePicker from '@components/StaticDatePicker';
import ControlButtons from '@shared/components/ControlButtons';
import MessageDialog from '@shared/components/MessageDialog';
import { differenceInCalendarWeeks, endOfDay, format, startOfDay } from 'date-fns';
import { WIDE_SIDEBAR_STATE } from '@components/client/queries';
import { useMediaQuery } from '@material-ui/core';
import { useDefaultCommonOutgoingNumber } from './hooks/useDefaultCommonOutgoingNumber.hooks';
import { useStatisticsByNumbersProps } from './hooks/useStatisticsByNumbersProps.hooks';
import {
  callType,
  filterOptionsPeriod,
  filterOptionsShort,
  filterTypeAllCallsOptions,
  filterTypeCallIcons,
  getRedirectDates,
  getSelectedOptionsType,
  periodShort,
  periodType,
} from '../StatisticsByCalls/StatisticsByCalls.constants';
import { UseNumbersStatisticStyles } from './StatisticsByNumbers.styles';
import { IHistoryStatistics } from '../StatisticsByCalls/StatisticsByCalls.interfaces';

export const StatisticsByNumbers: FunctionComponent = () => {
  const classes = UseNumbersStatisticStyles();
  const [searchParams, setSearchParams] = useSearchParams();
  const [translate] = useTranslation();
  const formMethods = useForm();
  const navigate = useNavigate();
  const { watch, setValue } = formMethods;

  const filterShortValue = watch('filterShort');
  const filterPeriodValue = watch('filterPeriod');
  const filterCallTypeValue = watch('callType');
  const sFrom = searchParams.get('from');
  const sTo = searchParams.get('to');
  const sFilterShort = searchParams.get('filterShort') || periodShort.day;
  const sChartType = searchParams.get('chartType');
  const sCallType = searchParams.get('callType');
  const sPeriod = searchParams.get('period');
  const parseSearchParams = (name: string) =>
    searchParams
      .getAll(name)
      .map((el) => el.split(','))
      .flat()
      .filter((val) => !!val)
      .map((value) => parseInt(value, 10));
  const sHiddenId = parseSearchParams('hiddenId');
  const sSelectedNumbersId = parseSearchParams('numbersId');
  const sType = getSelectedOptionsType(filterPeriodValue);
  const { commonNumber } = useDefaultCommonOutgoingNumber();
  const { data: sidebarState } = useQuery(WIDE_SIDEBAR_STATE);
  const { data: domainNumbers, loading: loadingDomainNumbers } = useQuery(DOMAIN_NUMBERS_QUERY, {
    fetchPolicy: 'cache-first',
  });
  const { data: isHistoryData } = useQuery(HAS_HISTORY_CALLS_QUERY);
  const hasHistoryCalls = isHistoryData?.hasHistoryCalls;

  const {
    currentDay,
    currentWeek,
    endWeek,
    pastWeek,
    endPastWeek,
    startMonth,
    endMonth,
    startPastMonth,
    endPastMonth,
    startWholeYear,
    endWholeYear,
  } = getDateList();

  const {
    selectedDayValue,
    selectedNumbersList,
    chartSwitch,
    hasSearchParams,
    showDayPicker,
    prevSelectedFilter,
    errorState,
    setShowDayPicker,
    setHasSearchParams,
    setSelectedValuesList,
    defaultNumbersFilterValue,
    setChartSwitch,
    setSelectedDayValue,
    setPrevSelectedFilter,
    setErrorState,
    disabledFilterShortList,
    theme,
    minDate,
    diagramData,
    setDiagramData,
  } = useStatisticsByNumbersProps(sType, filterShortValue, filterCallTypeValue);

  const selectedNumbersId = Object.keys(selectedNumbersList || {}).map((el) => Number(el));
  const isWide = sidebarState?.isWide || false;
  const smallPageMaxWidth = isWide ? '1720px' : '1600px';

  const isSmallPageSize = useMediaQuery(`(max-width:${smallPageMaxWidth})`, { noSsr: true });
  const domainNumbersData = useMemo(
    () => domainNumbers?.boughtDomainNumbers || [],
    [domainNumbers]
  );

  const disabledOptions = useMemo(() => {
    const result: Array<number> = [];

    if (!minDate) return result;

    const nowDateObject = new Date();
    const domainCreationDate = new Date(minDate);

    const [createMonth, nowMonth] = [domainCreationDate.getMonth(), nowDateObject.getMonth()];
    const [createYear, nowYear] = [domainCreationDate.getFullYear(), nowDateObject.getFullYear()];

    if (differenceInCalendarWeeks(domainCreationDate, nowDateObject, { weekStartsOn: 1 }) === 0)
      result.push(3); // past week
    if (createYear === nowYear && createMonth === nowMonth) result.push(5); // past month

    return result;
  }, [minDate]);

  useEffect(() => {
    setHasSearchParams(!searchParams.keys().next().done);
  }, [searchParams, setHasSearchParams]);

  useEffect(() => {
    if (
      sSelectedNumbersId.length &&
      !isArraysValuesEqual(sSelectedNumbersId, selectedNumbersId) &&
      domainNumbersData.length
    ) {
      const numbersData = sSelectedNumbersId?.reduce((res, numberId: number) => {
        const phoneNumber = domainNumbersData?.find((phoneConfig) => phoneConfig?.id === numberId)
          ?.phone;
        return { ...res, [numberId]: phoneNumber };
      }, {});
      setSelectedValuesList(numbersData);
    }
  }, [domainNumbersData, sSelectedNumbersId, selectedNumbersId, setSelectedValuesList]);

  const historyPeriodValues: { [key: string]: { from: Date; to: Date } } = useMemo(
    () => ({
      [periodType.week]: { from: currentWeek, to: endWeek },
      [periodType.pastWeek]: { from: pastWeek, to: endPastWeek },
      [periodType.month]: { from: startMonth, to: endMonth },
      [periodType.pastMonth]: { from: startPastMonth, to: endPastMonth },
      [periodType.year]: { from: startWholeYear, to: endWholeYear },
    }),
    [
      currentWeek,
      endWeek,
      pastWeek,
      endPastWeek,
      startMonth,
      endMonth,
      startPastMonth,
      endPastMonth,
      startWholeYear,
      endWholeYear,
    ]
  );

  const setPeriodValues = useCallback(
    (isFrom: boolean) => {
      if (filterPeriodValue) {
        if (filterPeriodValue === periodType.dayHour) {
          return isFrom ? sFrom || currentWeek : sTo || endWeek;
        }
        return historyPeriodValues[filterPeriodValue][isFrom ? 'from' : 'to'];
      }
      return isFrom ? currentWeek : endWeek;
    },
    [currentWeek, endWeek, filterPeriodValue, historyPeriodValues, sFrom, sTo]
  );

  const [getNumbersStatistics, { data: numbersStatistics, error: statisticsError }] = useLazyQuery(
    GET_NUMBERS_STATISTICS_QUERY,
    {
      variables: {
        data: {
          from: setPeriodValues(true),
          to: setPeriodValues(false),
          timeUnit: filterShortValue,
          numbers: selectedNumbersId.length
            ? selectedNumbersId
            : Object.keys(commonNumber || {}).map((el) => Number(el)),
        },
        onError: () => {
          setErrorState((prevState) => ({
            ...prevState,
            isOpen: true,
          }));
        },
      },
      fetchPolicy: 'cache-first',
    }
  );

  const historyData = useMemo(() => {
    if (!numbersStatistics) return null;
    return numbersStatistics?.getNumbersStatistics;
  }, [numbersStatistics]);

  const options = useMemo(() => {
    const getOrCreateTooltip = (chart: Chart) => {
      let tooltipEl = chart.canvas.parentNode?.querySelector('div');

      if (!tooltipEl) {
        tooltipEl = document.createElement('div');
        tooltipEl.className = classes.tooltipWrapperClass;

        const table = document.createElement('table');
        table.style.margin = '0px';

        tooltipEl.appendChild(table);
        chart.canvas.parentNode?.appendChild(tooltipEl);
      }

      return tooltipEl;
    };

    const externalTooltipHandler = (context: ScriptableTooltipContext<'line' | 'bar'>) => {
      const { chart, tooltip } = context;
      const tooltipEl = getOrCreateTooltip(chart);
      if (tooltip.opacity === 0) {
        tooltipEl.style.opacity = '0';
        return;
      }
      if (
        chartSwitch &&
        tooltip.dataPoints.findIndex((point: TooltipItem<'bar'>) => (point?.raw as number) > 0) < 0
      ) {
        tooltipEl.style.display = 'none';
        return;
      }
      tooltipEl.style.display = 'block';

      if (tooltip.dataPoints) {
        const { dataPoints } = tooltip;
        const tableHead = document.createElement('thead');
        tableHead.className = classes.tableHeadTooltipClass;

        const tr = document.createElement('tr');
        tr.className = classes.trTooltipClass;

        const th = document.createElement('th');
        th.className = classes.thTooltipClass;

        let labelText = '';
        if (sPeriod && sPeriod === String(periodType.dayHour)) {
          const rawIndex = dataPoints[0].dataIndex - 1;
          labelText = `${formatTimeHour(rawIndex)} - ${formatTimeHour(rawIndex + 1)}`;
        } else {
          labelText = dataPoints[0].label;
        }

        const renderLabelText = () => {
          if (filterCallTypeValue === callType.incoming) return 'TOTAL_INCOMING';
          if (filterCallTypeValue === callType.missed) return 'TOTAL_MISSED';
          if (filterCallTypeValue === callType.outgoing) return 'TOTAL_OUTGOING';
          if (filterCallTypeValue === callType.unsuccessful) return 'TOTAL_FAILED';
          return 'TOTAL_SUM';
        };
        const text = document.createTextNode(labelText);
        const totalText = document.createTextNode(`${translate(renderLabelText())}:`);
        const totalValue = document.createElement('span');
        totalValue.className = classes.tooltipTotalValueClass;
        const dataPointsCallsArray = dataPoints.map(
          (item) => item.dataset.data[dataPoints[0].dataIndex]
        );
        const totalCallsValue = dataPointsCallsArray.reduce((a, b) => Number(a) + Number(b), 0);
        totalValue.append(String(totalCallsValue));

        tableHead.appendChild(text);
        th.appendChild(totalText);
        th.appendChild(totalValue);
        tr.appendChild(th);
        tableHead.appendChild(tr);

        const tableBody = document.createElement('tbody');
        const dataOrder = dataPoints.map((_, index) => index);
        const index = dataPoints[0].dataIndex;
        dataOrder.forEach((i: number) => {
          const { datasetIndex } = dataPoints[i];
          const { datasets } = diagramData || {};
          const point = datasets[datasetIndex]?.data[index];
          const leftTextSpan = document.createElement('span');
          leftTextSpan.className = classes.tooltipLeftTextClass;
          leftTextSpan.append(`${datasets[datasetIndex].label}`);

          const colorBlock = document.createElement('div');
          colorBlock.className = clsx(classes.tooltipBlockClass, {
            [classes.tooltipBlockCircle]: !chartSwitch,
          });
          colorBlock.style.backgroundColor = datasets[datasetIndex].borderColor;

          const rightTextSpan = document.createElement('span');
          rightTextSpan.className = classes.tooltipRightTextClass;
          const val = point || 0;
          rightTextSpan.append(`${val}`);

          const trd = document.createElement('tr');
          trd.style.backgroundColor = 'inherit';
          trd.style.borderWidth = '0px';

          const td = document.createElement('td');
          td.className = classes.tdTooltipClass;
          td.appendChild(colorBlock);
          td.appendChild(leftTextSpan);
          td.appendChild(rightTextSpan);
          trd.appendChild(td);
          tableBody.appendChild(trd);
        });

        const tableRoot = tooltipEl.querySelector('table');

        while (tableRoot?.firstChild) {
          tableRoot?.firstChild.remove();
        }

        tableRoot?.appendChild(tableHead);
        tableRoot?.appendChild(tableBody);
      }

      const {
        offsetLeft: positionX,
        width: canvasRawWidth,
        height: canvasRawHeight,
      } = chart.canvas;
      const canvasWidth = canvasRawWidth / window.devicePixelRatio;
      const canvasHeight = canvasRawHeight / window.devicePixelRatio;
      const { y: offsetTop } = chart.canvas.getBoundingClientRect();
      const { innerHeight: windowHeight } = window;
      const bottomPadding = windowHeight - canvasHeight - offsetTop;
      const { clientWidth: tooltipWidth, clientHeight: tooltipHeight } = tooltipEl;
      const paddingStepSize = 16;
      const xPosition = positionX + tooltip.caretX;
      const isTooltipReachBottom =
        tooltip.caretY + tooltipHeight / 2 + paddingStepSize > canvasHeight + bottomPadding;
      const setLeftPosition = () => {
        if (xPosition + tooltipWidth > canvasWidth) {
          return `${xPosition - tooltipWidth - paddingStepSize}px`;
        }
        return `${xPosition + paddingStepSize}px`;
      };

      const setTopPosition = () => {
        if (isTooltipReachBottom) {
          return `${canvasHeight - (tooltipHeight + paddingStepSize)}px`;
        }
        if (tooltip.caretY - tooltipHeight / 2 < 0) {
          return `${paddingStepSize}px`;
        }
        return `${tooltip.caretY - tooltipHeight / 2}px`;
      };

      const setArrowClass = () => {
        let isArrowBottom = false;
        let isArrowLeft = false;
        if (isTooltipReachBottom) {
          isArrowBottom = true;
        }
        if (xPosition + tooltipWidth > canvasWidth) {
          isArrowLeft = true;
        }
        return clsx(classes.tooltipWrapperClass, {
          [classes.tooltipArrowLeft]: isArrowLeft,
          [classes.tooltipArrowRight]: !isArrowLeft,
          [classes.tooltipArrowBottomPosition]: isArrowBottom,
        });
      };

      tooltipEl.style.opacity = '1';
      tooltipEl.style.left = setLeftPosition();
      tooltipEl.style.top = setTopPosition();
      tooltipEl.style.font = String((tooltip.options.bodyFont as FontSpec)?.size);
      tooltipEl.className = setArrowClass();
      tooltipEl.style.padding = `${Number(tooltip.options.padding) * 2}px`;
    };
    const now = new Date();
    const xMaxVisible = historyData
      ? historyData[0]?.stat?.findIndex((el: IHistoryStatistics) => new Date(el.timePeriod) > now)
      : 0;

    const annotationVisible =
      xMaxVisible &&
      xMaxVisible >= 0 &&
      ((historyData?.length || 0) - (xMaxVisible || 0)) / (historyData?.length || 1) > 0.1;

    return {
      responsive: true,
      aspectRatio: isSmallPageSize ? 3.25 : 2.5,
      scales: {
        y: {
          beginAtZero: true,
          grace: '1%',
          ticks: {
            callback: (value: number): string => {
              if (value !== Math.round(value)) {
                return '';
              }
              return String(value);
            },
          },
        },
        x: {
          grace: '10%',
        },
      },
      interaction: {
        intersect: false,
        mode: 'index' as InteractionMode,
        axis: 'x',
      },
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          enabled: false,
          position: 'nearest' as TooltipPositioner,
          external: externalTooltipHandler,
        },
        annotation: {
          annotations: {
            nodata: {
              type: 'box',
              drawTime: 'beforeDatasetsDraw',
              display: xMaxVisible >= 0,
              xMin: chartSwitch ? xMaxVisible + 0.5 : xMaxVisible + 0.2,
              borderColor: theme?.color?.tertiary[100],
              backgroundColor: `${theme?.color?.tertiary[100]}99`, // opacity: 0.6
              label: {
                content:
                  annotationVisible && translate('THERE_IS_NO_DATA_TO_PLOT_THE_GRAPH').split('\n'),
                enabled: true,
                color: theme?.color?.tertiary[600],
                textAlign: 'center',
              },
            },
          },
        },
      },
      elements: {
        point: {
          radius: 2,
          pointStyle: 'circle',
          hoverRadius: 6,
        },
      },
    };
  }, [
    historyData,
    chartSwitch,
    theme,
    translate,
    classes,
    sPeriod,
    filterCallTypeValue,
    diagramData,
    isSmallPageSize,
  ]);

  const handlePointClick = useCallback(
    (element: InteractionItem[]) => {
      if (element[0]) {
        let historyPath = '';
        const { datasetIndex } = element[0];
        const numbersIdArray = Object.keys(selectedNumbersList || {});
        const numbersValuesArray = Object.values(selectedNumbersList || {});
        const { dateFrom, dateTo } = getRedirectDates(
          historyData?.[datasetIndex]?.stat[element[0].index - 1]?.timePeriod,
          filterShortValue
        );
        if (numbersIdArray[datasetIndex]) {
          historyPath = `/history/?from=${dateFrom}&to=${dateTo}&period=custom&via=${numbersValuesArray[datasetIndex].phone}`;
        }
        switch (filterCallTypeValue) {
          case callType.incoming:
            historyPath += '&direction=In';
            break;
          case callType.outgoing:
            historyPath += '&direction=Out';
            break;
          case callType.missed:
            historyPath += '&direction=In&missed=true';
            break;
          case callType.unsuccessful:
            historyPath += '&direction=Out&missed=true';
            break;
          default:
        }
        setTimeout(() => {
          navigate(historyPath);
        });
      }
    },
    [filterCallTypeValue, filterShortValue, historyData, navigate, selectedNumbersList]
  );

  useEffect(() => {
    if (historyData) {
      setDiagramData({
        data: historyData,
        isDataUpdated: true,
        hiddenIdList: sHiddenId,
      });
    }
  }, [historyData, sHiddenId, setDiagramData]);

  useEffect(() => {
    if (filterCallTypeValue) {
      setDiagramData({
        data: historyData || undefined,
        prevData: diagramData,
        isDataUpdated: false,
        hiddenIdList: sHiddenId,
      });
    }
  }, [diagramData, filterCallTypeValue, historyData, sHiddenId, setDiagramData]);

  useEffect(() => {
    if (!selectedDayValue && sFrom && sTo) {
      const d = new Date(sFrom);
      setSelectedDayValue(d);
    }
  }, [selectedDayValue, sFrom, sTo, setSelectedDayValue]);

  useEffect(() => {
    if (selectedNumbersList || commonNumber) getNumbersStatistics();
  }, [commonNumber, getNumbersStatistics, selectedNumbersList]);

  useEffect(() => {
    if (!showDayPicker) {
      const periodNumberVal = Number(sPeriod);
      if (sCallType && sCallType !== filterCallTypeValue) setValue('callType', sCallType);
      if (periodNumberVal && periodNumberVal !== filterPeriodValue) {
        setValue('filterPeriod', periodNumberVal);
        if (periodNumberVal !== periodType.dayHour) {
          searchParams.set('from', historyPeriodValues[periodNumberVal].from.toISOString());
          searchParams.set('to', historyPeriodValues[periodNumberVal].to.toISOString());
          setSearchParams(searchParams);
        }
      }
      if (sFilterShort && sFilterShort !== filterShortValue && filterPeriodValue) {
        const d = disabledFilterShortList;
        if (d.includes(sFilterShort as periodShort)) {
          if (
            (d.includes(periodShort.day) || d.includes(periodShort.month)) &&
            d.includes(periodShort.hour)
          ) {
            searchParams.set('filterShort', periodShort.week);
            setValue('filterShort', periodShort.week);
          }
          if (
            d.includes(periodShort.week) &&
            d.includes(periodShort.month) &&
            d.includes(periodShort.hour)
          ) {
            searchParams.set('filterShort', periodShort.day);
            setValue('filterShort', periodShort.day);
          }
        } else {
          setValue('filterShort', sFilterShort as periodShort);
        }
        setSearchParams(searchParams);
      }
      if (!prevSelectedFilter && periodNumberVal === periodType.dayHour) {
        setPrevSelectedFilter({});
      }
      if (sChartType) {
        setChartSwitch(sChartType !== 'line');
      }
    }
  }, [
    sPeriod,
    searchParams,
    setSearchParams,
    historyPeriodValues,
    filterPeriodValue,
    setValue,
    sFilterShort,
    sChartType,
    filterShortValue,
    disabledFilterShortList,
    showDayPicker,
    prevSelectedFilter,
    setPrevSelectedFilter,
    setChartSwitch,
    sCallType,
    filterCallTypeValue,
  ]);

  useEffect(() => {
    if (!showDayPicker) {
      if (sFrom && sTo && filterShortValue === sFilterShort) {
        const f = filterShortValue;
        if (
          (sType === periodType.year && (f === periodShort.day || f === periodShort.hour)) ||
          (sType === periodType.month && (f === periodShort.month || f === periodShort.hour))
        ) {
          setValue('filterShort', periodShort.week);
        } else if (
          sType === periodType.week &&
          (f === periodShort.week || f === periodShort.month || f === periodShort.hour)
        ) {
          setValue('filterShort', periodShort.day);
        } else if (
          sType === periodType.dayHour &&
          (f === periodShort.day || f === periodShort.week || f === periodShort.month)
        ) {
          setValue('filterShort', periodShort.hour);
        } else if (showDayPicker) {
          getNumbersStatistics();
        }
      }
    }
  }, [
    sFrom,
    sTo,
    setValue,
    sType,
    filterShortValue,
    sFilterShort,
    showDayPicker,
    getNumbersStatistics,
    selectedNumbersId,
  ]);

  function handleReportClick() {
    const token = getStorageItem('token');
    let url = process.env.API_URL;
    if (token && url) {
      const reportFrom = sFrom || currentWeek.toISOString();
      const reportTo = sTo || endWeek.toISOString();
      const reportTimeUnit = sFilterShort || periodShort.day;
      url += `/history/numbers-statistics/report?from=${reportFrom}&to=${reportTo}&timeUnit=${reportTimeUnit}&numbers=${selectedNumbersId}&callType=${
        sCallType || filterCallTypeValue
      }&token=${token}`;
      window.open(url);
    }
  }

  function handleFilterCallType({ target: { value } }: ChangeEvent<HTMLInputElement>) {
    searchParams.set('callType', value);
    setSearchParams(searchParams);
  }

  function handleCloseErrorDialog() {
    setErrorState((prevState) => ({
      ...prevState,
      isOpen: false,
    }));
  }

  function handleDaySelectCancel() {
    setShowDayPicker(false);
    const prevDate = new Date(sFrom || currentDay);
    setSelectedDayValue(prevDate);
    if (!prevSelectedFilter) {
      setValue('filterPeriod', sPeriod || periodType.week);
    }
  }

  function handleResetDayFilter() {
    const { from, to, period, short } = prevSelectedFilter || {};
    setValue('filterPeriod', period ? Number(period) : periodType.week);
    searchParams.set('from', from || currentWeek.toISOString());
    searchParams.set('to', to || endWeek.toISOString());
    searchParams.set('filterShort', short || String(periodShort.day));
    searchParams.set('period', period || String(periodType.week));
    setSearchParams(searchParams);
    setPrevSelectedFilter(null);
  }

  function handleDaySelect(selectedDay: Date | null) {
    if (selectedDay) {
      setSelectedDayValue(selectedDay);
    }
  }

  function handleDaySelectConfirm() {
    if (sPeriod !== String(periodType.dayHour)) {
      setPrevSelectedFilter({
        from: sFrom,
        to: sTo,
        period: sPeriod,
        short: sFilterShort,
      });
    }
    if (selectedDayValue) {
      searchParams.set('from', startOfDay(selectedDayValue).toISOString());
      searchParams.set('to', endOfDay(selectedDayValue).toISOString());
      searchParams.set('filterShort', String(periodShort.hour));
      searchParams.set('period', String(periodType.dayHour));
      setSearchParams(searchParams);
    }
    setShowDayPicker(false);
  }

  function handleFilterChange(selectedValues: IFilterChangeValues | undefined) {
    if (selectedValues) {
      setSelectedValuesList(selectedValues);
    }
  }

  function handleFilterPeriodChange({ target: { value } }: ChangeEvent<HTMLInputElement>) {
    const sValue = Number(value);
    if (
      sValue === periodType.month ||
      sValue === periodType.pastMonth ||
      sValue === periodType.year
    ) {
      setChartSwitch(false);
    }
    if (sValue === periodType.week || sValue === periodType.pastWeek) {
      setChartSwitch(true);
    }
    if (sValue === periodType.dayHour) {
      setSelectedDayValue(currentDay);
      setShowDayPicker(true);
    } else {
      searchParams.set('from', historyPeriodValues[value].from.toISOString());
      searchParams.set('to', historyPeriodValues[value].to.toISOString());
      searchParams.set('period', value);
      setSearchParams(searchParams);
    }
  }

  function handleFilterShortChange({ target: { value } }: ChangeEvent<HTMLInputElement>) {
    searchParams.set('filterShort', value);
    setSearchParams(searchParams);
  }

  function handleSwitchChartType() {
    const chartType = !chartSwitch;
    setChartSwitch(chartType);
    searchParams.set('chartType', chartType ? 'bar' : 'line');
    setSearchParams(searchParams);
  }

  function handleLegendClick(number: IDatasetsData, index: number) {
    setDiagramData({
      data: historyData || undefined,
      prevData: diagramData,
      hiddenIndex: index,
      isHide: !number.hidden,
    });
    let newHiddenId = [...sHiddenId];
    if (newHiddenId.includes(number.numberId)) {
      newHiddenId = newHiddenId.filter((el) => el !== number.numberId);
    } else {
      newHiddenId.push(number.numberId);
    }
    searchParams.set('hiddenId', String(newHiddenId));
    setSearchParams(searchParams);
  }

  if (statisticsError) {
    return <PageError />;
  }

  const renderCustomLegends = () => {
    if (selectedNumbersList && diagramData) {
      return (
        <Flex className={classes.numbersStatisticLegendsWrapper} justifyContent={'center'}>
          {diagramData.datasets.map((number, index) => (
            <div
              className={clsx(classes.numbersStatisticCustomLegend, {
                [classes.numbersStatisticHiddenLegend]: number.hidden,
              })}
              key={`legend-${number.label}`}
            >
              <div
                className={clsx(classes.numbersStatisticColorBlock, {
                  [classes.numbersStatisticColorBlockOpacity]: number.hidden,
                })}
                style={{ backgroundColor: number.backgroundColor, transition: '0.75s' }}
              />
              <Typography
                className={clsx(classes.notCopiedText, classes.numberLegendText)}
                type={'text3'}
                color={number.hidden ? 'tertiary400' : 'tertiary900'}
              >
                {number.label}
              </Typography>
              {number.hidden ? (
                <EyeOffIcon
                  className={classes.numbersStatisticEyeIcon}
                  onClick={() => handleLegendClick(number, index)}
                />
              ) : (
                <EyeIcon
                  className={classes.numbersStatisticEyeIcon}
                  onClick={() => handleLegendClick(number, index)}
                />
              )}
            </div>
          ))}
        </Flex>
      );
    }
    return <div />;
  };

  const renderStatisticsChart = () => {
    if (selectedNumbersList) {
      if (chartSwitch) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore incompatible 'options' type
        return <BarDiagram options={options} data={diagramData} onClick={handlePointClick} />;
      }
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore incompatible 'options' type
      return <LineDiagram options={options} data={diagramData} onClick={handlePointClick} />;
    }
    return (
      <Flex
        className={classes.emptyBlockMargin}
        alignItems={'center'}
        justifyContent={'center'}
        direction={'column'}
      >
        <SearchIcon className={classes.statIcon} />
        <Typography type={'text3'} color={'tertiary900'}>
          {translate('NO_PERIOD_DATA')}
        </Typography>
      </Flex>
    );
  };

  const renderCustomOption = ({
    data,
    selected,
    content,
  }: ISelectFieldOptionRendererProps<{ value: string; title: string }>) => {
    const { value = '', title = '' } = data;
    const Icon = filterTypeCallIcons[value || callType.allCalls];
    const computedClass = clsx({
      [classes.black]: value === callType.allCalls || undefined,
      [classes.links]: value === callType.incoming,
      [classes.danger]: value === callType.missed,
      [classes.success]: value === callType.outgoing,
      [classes.warning]: value === callType.unsuccessful,
    });
    return (
      <div className={classes.customOption}>
        <Flex alignItems={'center'}>
          {selected && <CheckIcon className={classes.glyph} />}
          <div className={clsx(!selected && !content && classes.customOptionMarginLeft)} />
          <Typography
            className={clsx({ [classes.selectedText]: selected })}
            type={'text3'}
            color={'tertiary900'}
          >
            {translate(title)}
          </Typography>
        </Flex>
        <Icon className={computedClass} width={16} height={16} />
      </div>
    );
  };

  return (
    <Flex direction={'column'} className={classes.root}>
      <FormProvider {...formMethods}>
        <form action={''}>
          <BodyContainer
            breadCrumbs={
              <>
                <Breadcrumbs />
                <div className={classes.numbersStatisticControlsWrapper}>
                  <FilterByNumbers
                    data={domainNumbersData}
                    onFilterChange={(selectedValues) => handleFilterChange(selectedValues)}
                    loading={loadingDomainNumbers}
                    defaultValue={
                      sSelectedNumbersId.length ? defaultNumbersFilterValue : commonNumber
                    }
                    maxSelectedLength={
                      domainNumbersData?.length >= 5 ? 5 : domainNumbersData?.length
                    }
                  />
                  <Flex>
                    <div className={classes.statisticsLineItem}>
                      <SelectField
                        className={clsx(classes.defaultElementWidth14, classes.selectRoot)}
                        data={filterTypeAllCallsOptions}
                        name={'callType'}
                        defaultValue={callType.allCalls}
                        size={'small'}
                        translating
                        titleKey={'title'}
                        valueKey={'value'}
                        Renderer={renderCustomOption}
                        onChange={handleFilterCallType}
                      />
                    </div>
                    <div className={classes.statisticsLineItem}>
                      <SelectField
                        translating
                        defaultValue={periodShort.day}
                        name={'filterShort'}
                        size={'small'}
                        className={classes.defaultElementWidth9}
                        data={filterOptionsShort}
                        onChange={handleFilterShortChange}
                        disabledOptions={disabledFilterShortList}
                        titleKey={'title'}
                        valueKey={'value'}
                      />
                    </div>
                    <div className={classes.statisticsLineItem}>
                      <SelectField
                        translating
                        defaultValue={periodType.week}
                        name={'filterPeriod'}
                        size={'small'}
                        className={clsx(classes.defaultElementWidth14, {
                          [classes.statisticsHiddenElement]: prevSelectedFilter,
                        })}
                        onChange={handleFilterPeriodChange}
                        data={filterOptionsPeriod}
                        disabledOptions={disabledOptions}
                        titleKey={'title'}
                        valueKey={'value'}
                      />
                      {prevSelectedFilter && (
                        <Flex
                          className={clsx(
                            classes.statisticsDayWrapper,
                            classes.defaultElementWidth14
                          )}
                          justifyContent={'spaceBetween'}
                        >
                          <Button
                            clear
                            className={classes.statisticsDayFilterButton}
                            onClick={() => setShowDayPicker(true)}
                          >
                            <Typography
                              className={classes.statisticsDayText}
                              type={'text3'}
                              color={'tertiary900'}
                            >
                              {format(selectedDayValue || new Date(currentDay), 'dd.MM.yyyy')}
                            </Typography>
                          </Button>
                          <Button clear onClick={handleResetDayFilter}>
                            <XIcon className={classes.statisticsDayFilterReset} />
                          </Button>
                        </Flex>
                      )}
                    </div>
                    <div className={classes.statisticsLineItem}>
                      <Button clear rounded onClick={handleSwitchChartType}>
                        {!chartSwitch ? (
                          <BarChartIcon className={classes.iconBtn} />
                        ) : (
                          <LineChartIcon className={classes.iconBtn} />
                        )}
                      </Button>
                    </div>
                    <div className={classes.statisticsLineItem}>
                      <Button onClick={handleReportClick} clear rounded>
                        <ExcelExportIcon className={classes.xls} />
                      </Button>
                    </div>
                  </Flex>
                </div>
              </>
            }
          >
            {hasSearchParams || hasHistoryCalls ? (
              <>
                {renderCustomLegends()}
                <div
                  className={isSmallPageSize ? classes.statisticsRootSmall : classes.statisticsRoot}
                >
                  {renderStatisticsChart()}
                </div>
              </>
            ) : (
              <div className={classes.emptyBlock}>
                <StatisticsIcon className={classes.statisticsIcon} />
                <Typography color={'tertiary600'} type={'text3'}>
                  {translate('HERE_WILL_BE_NUMBERS_STATISTIC')}
                </Typography>
              </div>
            )}
            <MessageDialog
              isOpen={errorState.isOpen}
              title={translate(errorState.title)}
              message={translate(errorState.message)}
              onCancel={handleCloseErrorDialog}
              renderControls={
                <Button title={translate('CLOSE')} onClick={handleCloseErrorDialog} smallHeight />
              }
            />
            <MessageDialog
              isOpen={showDayPicker}
              contentClass={classes.statisticsDatePicker}
              title={translate('CHOOSE_DAY')}
              onCancel={handleDaySelectCancel}
              renderContent={
                <>
                  <StaticDatePicker
                    minDate={minDate}
                    value={selectedDayValue}
                    onChange={handleDaySelect}
                  />
                  <div className={classes.statisticsDatePickerControls}>
                    <ControlButtons
                      confirmTitle={translate('APPLY')}
                      cancelTitle={translate('CANCEL')}
                      cancelVariant="secondary"
                      onConfirmClick={handleDaySelectConfirm}
                      onCancelClick={handleDaySelectCancel}
                      small
                    />
                  </div>
                </>
              }
            />
          </BodyContainer>
        </form>
      </FormProvider>
    </Flex>
  );
};

export default StatisticsByNumbers;
