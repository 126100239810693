import { makeStyles } from '@material-ui/core';

export const useSelectFieldStyles = makeStyles(
  ({ color: { secondary, tertiary, base, primary } }) => ({
    root: {
      padding: 'initial',
    },
    selectRoot: {
      marginTop: '1.5em',
      width: '13.5em',
    },
    selectLabel: {
      fontSize: '0.875em',
      transform: 'initial',
      color: tertiary[700],
    },
    chevron: {
      position: 'absolute',
      display: 'flex',
      flexDirection: 'row-reverse',
      width: '100%',
      right: '1em',
      pointerEvents: 'none',
    },
    paper: {
      borderRadius: '0 0 0.75em 0.75em',
      paddingTop: '0',
      paddingBottom: '0',
      boxShadow: `0px 0.25em 0.25em ${tertiary['400']}`,
      maxHeight: '23em',
      maxWidth: '25em',
    },
    outlined: {
      padding: 'initial',
      '&$select': {
        padding: 'initial',
      },
    },
    select: {
      '&:focus': {
        background: base,
      },
      '&:read-only': {
        background: base,
      },
    },
    selected: {},
    rootPrimary: {
      '&:hover': {
        background: primary[100],
      },
      '&$selected': {
        background: base,
        '&:hover': {
          background: primary[100],
        },
      },
    },
    rootSecondary: {
      '&:hover': {
        background: tertiary[100],
      },
      '&$selected': {
        background: base,
        '&:hover': {
          background: secondary[300],
        },
      },
    },
    inputRoot: {
      fontSize: '1em',
      '&.Mui-focused fieldset.MuiOutlinedInput-notchedOutline': {
        border: `1px solid ${primary[700]}`,
      },
    },
    caption: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  }),
  {
    index: 1,
  }
);

export const useListSubheaderStyles = makeStyles(
  ({ color: { tertiary } }) => ({
    root: {
      backgroundColor: tertiary[50],
      color: tertiary[600],
      padding: '0.5em 1em',
      fontSize: '0.625em',
      height: '1.5em',
      lineHeight: '0.625em',
      '&:focus': {
        outline: 'none',
      },
    },
  }),
  {
    index: 1,
  }
);

export const useSelectFieldOptionStyles = makeStyles(
  ({ color: { secondary, tertiary, primary, base } }) => ({
    root: {
      padding: '0.6em 0.5em 0.6em 0.825em',
      '& > span': {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        paddingRight: '1.75em',
      },
      '&:hover': {
        background: tertiary[300],
      },
      '&$selected': {
        color: tertiary[900],
        background: base,
        '&:hover': {
          background: secondary[300],
        },
      },
    },
    sizeSmall: {
      padding: '0.345em 0.5em 0.345em 0.825em',
    },
    rootPrimary: {
      '&:hover': {
        background: tertiary[50],
      },
      '&$selected': {
        color: primary[800],
        background: base,
        '&:hover': {
          background: tertiary[50],
        },
      },
    },
    rootSecondary: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      '&:hover': {
        background: tertiary[100],
      },
      '&$selected': {
        color: tertiary[900],
        background: base,
        '&:hover': {
          background: tertiary[100],
        },
      },
    },
    customRoot: {
      padding: 'initial',
    },
    disabledOption: {
      backgroundColor: base,
      '&:hover': {
        backgroundColor: base,
        cursor: 'default',
      },
    },
    selected: {},
    disabled: {},
    glyph: {
      minWidth: '1em',
      marginRight: '0.5em',
      color: tertiary[600],
      '& path': {
        fill: primary[700],
      },
    },
    glyphPrimary: {
      color: primary[800],
    },
    glyphSecondary: {
      color: secondary[600],
    },
    textMarginLeft: {
      marginLeft: '1.75em',
    },
  }),
  {
    index: 1,
  }
);
