import { makeStyles } from '@material-ui/core';
import styleConstants from '@components/styles';

export const UseNumbersStatisticStyles = makeStyles(
  ({
    color: { tertiary, links, success, danger, base, warning, primary },
    transitions: {
      create: createTransition,
      easing: { sharp },
      duration: { enteringScreen },
    },
  }) => ({
    root: {
      width: '100%',
      height: '100%',
    },
    statisticsLineItem: {
      marginLeft: '1em',
      position: 'relative',
    },
    statisticsRoot: {
      position: 'relative',
      padding: '0 1em 2em 1em',
    },
    statisticsRootSmall: {
      position: 'relative',
      padding: '0 1.5em 2em 1.5em',
    },
    headerWrapper: {
      width: '100%',
      backgroundColor: base,
      display: 'flex',
      flexDirection: 'column',
    },
    numbersStatisticControlsWrapper: {
      margin: '0 2em',
      paddingBottom: '1em',
      display: 'flex',
      justifyContent: 'space-between',
    },
    tooltipWrapperClass: {
      background: base,
      border: `1px solid ${tertiary[200]}`,
      borderRadius: '12px',
      color: tertiary[900],
      opacity: 1,
      pointerEvents: 'none',
      position: 'absolute',
      boxShadow: '0 4px 10px 0 rgba(0, 0, 0, 0.1)',
      transition: 'all .1s ease',
      '&:after, &:before': {
        display: 'block',
        content: '""',
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        borderTop: 'solid 0.75em transparent',
        borderBottom: 'solid 0.75em transparent',
      },
    },
    tooltipLeftTextClass: {
      display: 'inline-block',
      fontSize: '0.75em',
      width: 'max-content',
      verticalAlign: 'middle',
    },
    tooltipTotalValueClass: {
      display: 'inline-block',
      width: 'max-content',
      height: '2em',
    },
    tableHeadTooltipClass: {
      fontWeight: 'bold',
      height: '3.5em',
    },
    trTooltipClass: {
      borderWidth: 0,
      fontSize: '0.875em',
      height: '2em',
      verticalAlign: 'middle',
      fontWeight: 'normal',
    },
    thTooltipClass: {
      borderWidth: '0px',
      textAlign: 'left',
      display: 'flex',
      justifyContent: 'space-between',
    },
    tdTooltipClass: {
      borderWidth: '0px',
      textAlign: 'right',
      display: 'flex',
      justifyContent: 'space-between',
      fontSize: '1.125em',
      margin: '0.125em 0',
    },
    tooltipBlockClass: {
      width: '0.5em',
      height: '0.5em',
      marginRight: '0.5em',
      marginTop: '3px',
      borderRadius: '50%',
      display: 'inline-block',
      verticalAlign: 'middle',
      backgroundColor: base,
    },
    tooltipBlockCircle: {
      borderRadius: '50%',
    },
    tooltipRightTextClass: {
      display: 'inline-block',
      fontSize: '0.875em',
      marginLeft: '1em',
      minWidth: '3em',
      verticalAlign: 'middle',
    },
    tooltipArrowRight: {
      '&:after': {
        borderRight: `solid 0.75em ${base}`,
        left: '-0.75em',
      },
      '&:before': {
        borderRight: `solid 0.75em ${tertiary[200]}`,
        marginLeft: '-1px',
        left: '-0.75em',
      },
    },
    tooltipArrowLeft: {
      '&:after': {
        borderLeft: `solid 0.75em ${base}`,
        right: '-0.75em',
      },
      '&:before': {
        borderLeft: `solid 0.75em ${tertiary[200]}`,
        marginRight: '-1px',
        right: '-0.75em',
      },
    },
    tooltipArrowBottomPosition: {
      '&:after, &:before': {
        top: '65%',
      },
    },
    selectRoot: {
      '& .MuiSelect-root > li > * > svg': {
        display: 'none',
      },
    },
    statisticsDatePicker: {
      width: '18em',
      paddingLeft: 0,
      paddingTop: 0,
      paddingRight: 0,
    },
    statisticsDatePickerControls: {
      padding: '0 1.5em',
    },
    statisticsDayWrapper: {
      border: `solid 1px ${primary[700]}`,
      borderRadius: '4px',
    },
    statisticsDayFilterButton: {
      width: '100%',
      textAlign: 'left',
    },
    statisticsDayText: {
      padding: '0.32em 0.75em 0.2em',
      display: 'block',
      width: '100%',
    },
    statisticsDayFilterReset: {
      marginRight: '0.5em',
      marginLeft: '0.5em',
      color: 'black',
    },
    statisticsHiddenElement: {
      display: 'none',
    },
    numbersStatisticLegendsWrapper: {
      margin: '0.5em 2em 0.5em',
    },
    numbersStatisticCustomLegend: {
      marginRight: '1em',
      width: '13em',
      height: '2.5em',
      borderRadius: '0.25em',
      border: `1px solid ${tertiary[300]}`,
      backgroundColor: tertiary[100],
      alignItems: 'center',
      display: 'flex',
    },
    numbersStatisticHiddenLegend: {
      transition: '0.65s ease',
      backgroundColor: tertiary[50],
    },
    numbersStatisticColorBlock: {
      width: '0.75em',
      height: '0.75em',
      borderRadius: '0.25em',
      margin: '0 0.75em 0 0.5em',
    },
    numbersStatisticColorBlockOpacity: {
      opacity: '30%',
    },
    numbersStatisticEyeIcon: {
      marginLeft: '0.5em',
      marginRight: '0.5em',
      '&:hover': {
        cursor: 'pointer',
        transition: '0.5s ease',
        color: primary[700],
      },
    },
    numberLegendText: {
      transition: '0.65s ease',
    },
    customOption: {
      height: '2em',
      padding: '0 1em',
      alignItems: 'center',
      display: 'flex',
      flexFlow: 'row nowrap',
      pointerEvents: 'none',
      justifyContent: 'space-between',
      width: '100%',
    },
    customOptionMarginLeft: {
      marginLeft: '1.5em',
    },
    glyph: {
      marginTop: '-2px',
      marginRight: '0.5em',
      color: primary[700],
    },
    selectedText: {
      color: primary[700],
    },
    success: {
      color: success[600],
    },
    links: {
      color: links[600],
    },
    black: {
      color: 'black',
    },
    danger: {
      color: danger[600],
    },
    warning: {
      color: warning[600],
    },
    iconBtn: {
      transition: createTransition(['color'], {
        easing: sharp,
        duration: enteringScreen,
      }),
      color: tertiary['900'],
      '&:hover': {
        color: primary['700'],
      },
    },
    xls: {
      transition: createTransition(['color'], {
        easing: sharp,
        duration: enteringScreen,
      }),
      color: tertiary['900'],
      '&:hover': {
        backgroundColor: tertiary[100],
        borderRadius: '0.325em',
      },
    },
    statIcon: {
      width: '4em',
      height: '4em',
      color: primary[700],
      opacity: '50%',
      marginBottom: '3em',
    },
    emptyBlockMargin: {
      marginTop: '10em',
    },
    emptyBlock: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginTop: '14em',
    },
    statisticsIcon: {
      minWidth: '4em',
      minHeight: '4em',
      color: primary[700],
      opacity: '50%',
      marginBottom: '2.5em',
    },
    ...styleConstants,
  }),
  {
    index: 2,
  }
);
