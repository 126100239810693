import React, { FunctionComponent, memo, useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import Button from '@shared/components/Button';
import Typography from '@shared/components/Typography';
import FormFieldRhfUncontrolled from '@shared/components/FormFieldRhfUncontrolled';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Flex from '@shared/components/Flex';
import MessageDialog from '@shared/components/MessageDialog';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { INSTALL_YCLIENTS_INTEGRATION_MUTATION } from '@/client/mutations';
import Translate from '@shared/components/Translate';
import { getErrorMessageFromGraphqlError } from '@shared/utils/apollo';
import { globalNotification$ } from '@components/GlobalSnackbarNotification';
import * as Sentry from '@sentry/react';
import { IIntegrationAuthorizationProps } from '../IntegrationForm.interfaces';
import { useIntegrationFormStyles } from '../IntegrationForm.styles';
import WhereToFindImg from './images/whereToFind.png';

type FormFields = {
  crmToken: string;
};

export const YClientsCRMAuthorization: FunctionComponent<IIntegrationAuthorizationProps> = ({
  isOpen,
  onClose,
}) => {
  const classes = useIntegrationFormStyles();
  const [translate] = useTranslation();
  const formMethods = useForm<FormFields>({ defaultValues: { crmToken: '' } });
  const navigate = useNavigate();
  const { clearErrors, setError } = formMethods;
  const [searchParams] = useSearchParams();
  const sError = searchParams.get('error');
  const [installIntegration, { loading: loadingInstallYClients }] = useMutation(
    INSTALL_YCLIENTS_INTEGRATION_MUTATION
  );
  const [whereToFindShow, setWhereToFindShow] = useState(false);
  const [errorDialogState, setErrorDialogState] = useState({
    isOpen: false,
    title: 'SOMETHING_WENT_WRONG',
    message: '',
  });

  const handleClose = () => {
    if (onClose) {
      onClose();
    }
    setTimeout(() => {
      formMethods.reset();
    }, 200);
  };

  useEffect(() => {
    if (sError) {
      setErrorDialogState((prevState) => ({
        ...prevState,
        isOpen: true,
        message: 'SOMETHING_WRONG_MESSAGE',
      }));
      onClose?.();
    }
  }, [sError]);

  function handleYClientsAppInstall(formFields: FormFields) {
    const tokenValue = formFields.crmToken;

    if (tokenValue === '') {
      setError('crmToken', {
        type: 'error',
        message: translate('EMPTY_TOKEN'),
      });
    } else {
      installIntegration({
        variables: {
          data: {
            token: tokenValue,
          },
        },
      })
        .then((res) => {
          if (!res.data) {
            Sentry.captureException(
              Error('Cannot get installYClientsIntegration variable. res.data is not defined.')
            );
            globalNotification$.show('danger', 'SOMETHING_WENT_WRONG');
            return;
          }
          navigate(`/integrations/edit-integration/${res.data.installYClientsIntegration}`);
        })
        .catch((error) => {
          let tokenErrorMessage = 'TOKEN_NOT_FOUND';
          const messageFromError = getErrorMessageFromGraphqlError(error);
          if (messageFromError === 'TOKEN_IS_ALREADY_IN_USE') {
            tokenErrorMessage = 'TOKEN_IN_USE_ERROR';
          }
          setErrorDialogState((prevState) => ({
            ...prevState,
            isOpen: true,
            message: tokenErrorMessage,
          }));
        });
    }
  }

  function handleCloseErrorDialog() {
    setErrorDialogState((prevState) => ({
      ...prevState,
      isOpen: false,
    }));
    if (sError) {
      navigate('/integrations');
    }
  }

  function handleWhereToFindClick() {
    setWhereToFindShow(true);
  }

  return (
    <FormProvider {...formMethods}>
      <MessageDialog
        isOpen={isOpen}
        contentClass={classes.integrationDialog}
        title={translate('INTEGRATION_INSTALL_TITLE')}
        onCancel={handleClose}
        renderContent={
          <>
            <div className={classes.integrationAuthFieldBlockSimple}>
              <Button
                onClick={handleWhereToFindClick}
                clear
                className={classes.integrationAuthWhereToFindButton}
              >
                <Typography type={'text4'} color={'link600'} underline>
                  {translate('WHERE_SEARCH')}
                </Typography>
              </Button>
              <FormFieldRhfUncontrolled
                label={translate('TOKEN')}
                name={'crmToken'}
                onChange={() => clearErrors('crmToken')}
                validate={(value) => {
                  if (!value) {
                    return translate('ENTER_TOKEN');
                  }
                  return true;
                }}
              />
            </div>
            <Flex justifyContent={'center'} alignItems={'flexEnd'}>
              <Button
                loading={loadingInstallYClients}
                className={classes.defaultElementWidth17}
                title={translate('INSTALL_CRM')}
                onClick={formMethods.handleSubmit(handleYClientsAppInstall)}
                smallHeight
              />
            </Flex>
          </>
        }
      />
      <MessageDialog
        isOpen={errorDialogState.isOpen}
        contentClass={classes.integrationDialog}
        title={translate(errorDialogState.title)}
        message={translate(errorDialogState.message)}
        onCancel={handleCloseErrorDialog}
        renderControls={
          <Button title={translate('CLOSE')} onClick={handleCloseErrorDialog} smallHeight />
        }
      />
      <MessageDialog
        isOpen={whereToFindShow}
        contentClass={classes.YClientsMessageDialogWhereToFindContent}
        paperClass={classes.YClientsMessageDialogWhereToFindPaper}
        title={translate('WHERE_SEARCH')}
        onCancel={() => setWhereToFindShow(false)}
        renderContent={
          <>
            <div className={classes.integrationAuthWhereFindBlock}>
              <Translate
                i18nKey="WHERE_SEARCH_MESSAGE"
                components={{
                  t: <Typography color={'tertiary900'} type={'text3'} />,
                  b: <Typography color={'tertiary900'} type={'default'} bold />,
                }}
              />
            </div>
            <div className={classes.integrationAuthWhereFindBlock}>
              <img src={WhereToFindImg} alt={''} />
            </div>
          </>
        }
      />
    </FormProvider>
  );
};

export default memo(YClientsCRMAuthorization);
