import { fade, makeStyles } from '@material-ui/core/styles';
import styleConstants from '@components/styles';

export const useDrawerNumbersStyle = makeStyles(
  ({
    color: { base, tertiary, primary },
    transitions: {
      create: createTransition,
      easing: { sharp },
      duration: { enteringScreen },
    },
  }) => ({
    selectText: {
      padding: '0.8em 1em',
      display: 'flex',
      width: '100%',
      justifyContent: 'space-between',
    },
    selectItemCode: {
      padding: '0.6em 0 0.6em 1em',
      display: 'flex',
      width: '15em',
      justifyContent: 'flex-start',
      alignItems: 'center',
      '& > span': {
        paddingRight: '0.5em',
      },
    },
    selectNumberCode: {
      minWidth: '2.5em',
    },
    selectedNumberCodeMarginLeft: {
      marginLeft: '1.75em',
    },
    selectIcon: {
      color: primary[700],
      marginRight: '0.5em',
    },
    selectRoot: {
      padding: 0,
      '& li:hover': {
        backgroundColor: 'transparent',
      },
      '& svg': {
        display: 'none',
      },
    },
    phoneNumberRightMenu: {
      width: '33em',
      overflow: 'hidden!important',
    },

    phoneNumberMenuFilter: {
      padding: '1em 2em 1.5em',
      height: '11.18em',
      borderBottom: `solid 1px ${tertiary[100]}`,
    },
    phoneNumberFilterItem: {
      display: 'flex',
      justifyContent: 'space-between',
      paddingTop: '1em',
    },
    phoneNumberCityCode: {
      width: '16.5em',
      margin: 0,
      backgroundColor: 'initial',
    },
    phoneNumberType: {
      width: '11.5em',
      margin: 0,
    },
    phoneNumberPart: {
      width: '100%',
      margin: 0,
    },
    phoneNumberMenuContent: {
      position: 'absolute',
      top: '14.18em',
      left: 0,
      right: 0,
      bottom: '4.5em',
    },
    phoneNumberMenuContentOpen: {
      bottom: '13.5em',
    },
    phoneNumberListPreloader: {
      position: 'absolute',
      zIndex: 10,
      width: '100%',
      height: '100%',
      top: 0,
      left: 0,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: fade(`${base}`, 0.8),
    },
    phoneNumberCancelButtonBlock: {
      height: '3em',

      display: 'flex',
      justifyContent: 'end',
    },
    phoneNumberBlock: {
      marginTop: '-1.5em',
      padding: '0 2em',
    },
    phoneNumberSelectDialog: {
      transition: createTransition(['margin-top'], {
        easing: sharp,
        duration: enteringScreen,
      }),
      position: 'absolute',
      left: 0,
      right: 0,
      height: '10em',
      top: '100%',
      marginTop: '-4.5em',
      backgroundColor: tertiary[50],
    },
    phoneNumberSelectDialogOpen: {
      marginTop: '-14.5em',
    },
    phoneNumberSmsReceiveNotSupportedLabel: {
      height: '1em',
    },
    phoneNumberSelectLine: {
      marginTop: '.75em',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    phoneNumberLabelWrapper: {
      marginBottom: '.25em',
    },
    phoneNumberReserveButton: {
      width: '12.5em',
      marginRight: '1em',
    },
    phoneNumberCancelButton: {
      padding: 0,
      width: '2em',
      height: '2em',

      margin: '.5em .5em 0 0',
    },
    phoneNumberInformation: {
      display: 'flex',
      flexDirection: 'column',
      paddingRight: '0.5em',
      '& > *': {
        marginBottom: '0.5em',
      },
    },
    phoneNumberCloseMenu: {
      position: 'absolute',
      padding: '0.65em',
      right: '1em',
      top: '0.25em',
      color: base,
      '&:hover': {
        borderColor: 'transparent',
      },
    },
    phoneNumberCounterWrap: {
      position: 'absolute',
      left: 0,
      right: '1em',
      top: 0,
      height: '1.5em',
      backgroundColor: base,
      zIndex: 10,
    },
    phoneNumberCounter: {
      position: 'absolute',
      left: '50%',
      transform: 'translateX(-55%)',
      padding: '0 0.5em',
      top: '-0.65em',
      backgroundColor: base,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 10,
      '& > *': {
        paddingLeft: '0.25em',
        '&:first-child': {
          paddingLeft: 0,
        },
      },
    },
    phoneCloseBtn: {
      '&:hover span:hover': {
        color: tertiary[900],
        borderBottomColor: tertiary[900],
      },
    },
    selectDivider: {
      width: '100%',
      margin: '0.25em 0',
      // the tertiary 300 compensates for the opacity of 0.38 for the disabled element
      borderBottom: `solid 1px ${tertiary[300]}`,
    },
    content: {
      padding: 0,
    },
    footer: {
      backgroundColor: base,
      zIndex: 50,
    },
    ...styleConstants,
  }),
  {
    index: 2,
  }
);
