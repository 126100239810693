import { ClientType } from './client';

export const payerTypeOptionsList = [
  {
    value: ClientType.Ul,
    titleCode: 'LEGAL_TYPE',
  },
  {
    value: ClientType.Ip,
    titleCode: 'BUSINESSMAN_TYPE',
  },
  {
    value: ClientType.Fl,
    titleCode: 'INDIVIDUAL_TYPE',
  },
];

export const partnerTypeOptionsList = [
  {
    value: 'Ul',
    titleCode: 'LEGAL_TYPE',
  },
  {
    value: 'Ip',
    titleCode: 'BUSINESSMAN_TYPE',
  },
];

export const genderOptions = [
  {
    value: 'male',
    titleCode: 'MALE',
  },
  {
    value: 'female',
    titleCode: 'FEMALE',
  },
];
