import React, { MouseEvent, useCallback, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMatch, useNavigate } from 'react-router-dom';
import { IIncomingNumberScenario } from '@/features/IncomingNumbers/IncomingNumbers.interfaces';
import { useMutation, useQuery } from '@apollo/client';
import { DOMAIN_NUMBERS_QUERY, INCOMING_NUMBER_QUERY } from '@/client/queries';
import { UPDATE_INCOMING_NUMBER_MUTATION } from '@/client/mutations';
import FormFieldRhfUncontrolled from '@shared/components/FormFieldRhfUncontrolled';
import { useFormErrors } from '@components/common/formErrors.hooks';
import SelectField, { ISelectFieldOptionRendererProps } from '@shared/components/SelectField';
import { usePrevious } from '@/common/hooks/usePrev.hooks';
import FormErrorMessage from '@components/FormErrorMessage';
import Typography from '@shared/components/Typography';
import PagePreloader from '@components/PagePreloader';
import ConfirmDialog, { ConfirmAction, IConfirmState } from '@components/ConfirmDialog';
import ControlButtons from '@shared/components/ControlButtons';
import BodyContainer from '@/layouts/BodyContainer';
import Flex from '@shared/components/Flex';
import BottomButtons from '@/layouts/BottomButtons';
import { Button } from '@shared/components/Button/Button';
import { CheckIcon, DocumentArrowIcon, PowerIcon } from '@shared/assets/images/icons';
import { formatPhone, integrationsLogoList } from '@components/utils';
import MessageDialog from '@shared/components/MessageDialog';
import clsx from 'clsx';
import ScenarioResponsibleLine from '@/features/Integrations/IntegrationForm/modules/integrationSettings/ScenarioResponsibleLine';
import { RESTIconSmall } from '@shared/assets/images/icons/integrations';
import { IncomingNumberQuery, IntegrationStatus } from '@/client/generated/graphql';
import Scheduler from './Scheduler/Scheduler';
import FormErrors from './FormErrors';
import {
  IIncomingNumberScenarioConfig,
  ScenariosTypes,
  SchedulePeriodsTypes,
  SchedulingMode,
} from './IncomingNumber.interfaces';
import { customPeriods, getDays, initialPeriods } from './Scheduler/Scheduler.constants';
import { IDay, ISchedulePeriod } from './Scheduler/Scheduler.interfaces';
import { useEditIncomingNumberStyles } from './IncomingNumber.styles';
import SchedulerPeriods from './SchedulerPeriods/SchedulerPeriods';
import { deserializeSchedule, serializeSchedule } from './IncomingNumber.helpers';
import { initPeriods, initScheduler } from './Scheduler/Scheduler.helpers';
import IncomingNumberScenarios from './Scenarios/IncomingNumberScenarios';
import IncomingNumberScenariosTree from './ScenariosTree/IncomingNumberScenariosTree';
import { SchedulingModesOptions } from './IncomingNumber.constants';

type IncomingNumberData = IncomingNumberQuery['getNumber'];
type FormFields = Partial<IncomingNumberData> & {
  atc: SchedulingMode;
  copiedId?: string;
};
type NumberData = Partial<IncomingNumberData> & {
  atc?: SchedulingMode;
};

export const IncomingNumber = () => {
  const classes = useEditIncomingNumberStyles();
  const [translate] = useTranslation();

  const navigate = useNavigate();
  const idMatch = useMatch('/:category/:subcategory/:id');
  const { params: { id = '' } = {} } = idMatch || {};
  const numbersListPath = '/calls/incoming/';

  const {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data: dataIncomingNumber,
    loading: loadingIncomingNumber,
    refetch,
  } = useQuery(INCOMING_NUMBER_QUERY, {
    fetchPolicy: 'no-cache',
    variables: {
      data: { id: Number.isFinite(Number(id)) ? Number(id) : undefined },
    },
  });
  const incomingNumber = dataIncomingNumber?.getNumber;

  const { data: numbersData } = useQuery(DOMAIN_NUMBERS_QUERY, { fetchPolicy: 'cache-first' });
  const domainNumbers =
    numbersData?.boughtDomainNumbers.filter((number) => number.id !== Number(id)) || [];
  const prevLoadingIncomingNumber = usePrevious(loadingIncomingNumber);

  const [updateIncomingNumber, { loading, error, called }] = useMutation(
    UPDATE_INCOMING_NUMBER_MUTATION
  );

  const [data, setData] = useState<NumberData | undefined>(incomingNumber);
  const [scenarioConfig, setScenarioConfig] = useState<IIncomingNumberScenarioConfig | undefined>();
  const [schedulePeriods, setSchedulePeriods] = useState<ISchedulePeriod[]>(initialPeriods);
  const [activePeriod, setActivePeriod] = useState<SchedulePeriodsTypes>(
    SchedulePeriodsTypes.WorkingHours
  );
  const [, setSchedulerOpen] = useState(true);
  const [isCopyNumberSettingsModalOpen, setIsCopyNumberSettingsModalOpen] = useState(false);
  const [schedule, setSchedule] = useState<IDay[]>(getDays);
  const [errors, setFormErrors] = useState<string[]>([]);
  const [blockedPath, setBlockedPath] = useState<string | null>(null);
  const [isCopyNumberSettings, setIsCopyNumberSettings] = useState(false);
  const [isChanged, setIsChanged] = useState<IConfirmState>({
    isBlocking: false,
    action: ConfirmAction.Edit,
  });

  const formMethods = useForm<FormFields>({
    // TODO: move to serialization function
    defaultValues: {
      name: data?.name,
      atc: data?.atc ? SchedulingMode.ATC : SchedulingMode.Scheduled,
      copiedId: '',
    },
  });
  const { handleSubmit, watch, reset, setValue } = formMethods;
  const selectedNumber = watch('copiedId');
  const numberName = watch('name');

  useEffect(() => {
    if (isChanged.action === ConfirmAction.Cancel && !isChanged.isBlocking) {
      navigate(numbersListPath);
    }
  }, [isChanged, navigate]);

  function getBlockedPath(path: string) {
    setBlockedPath(path);
  }

  function handleCancelChanges() {
    setIsChanged({
      isBlocking: false,
      action: ConfirmAction.Cancel,
    });
  }

  function handleCloseCopyNumberSettingsDialog() {
    setValue('copiedId', undefined);
    setIsCopyNumberSettingsModalOpen(false);
  }

  const handleScheduleChanges = () => {
    setIsChanged({
      isBlocking: true,
      action: ConfirmAction.Edit,
    });
  };

  useEffect(() => {
    if (called && !loading && !error) {
      navigate(blockedPath || numbersListPath);
    }
  }, [called, loading, error, navigate, blockedPath]);

  useEffect(() => {
    // TODO replace with dialog
    setFormErrors([]);
    const newErrors: string[] = [];
    if (error) {
      error.graphQLErrors.forEach((err) => {
        const errorMessage = translate(err?.extensions?.exception?.message);
        if (errorMessage === err?.extensions?.exception?.message) {
          // if we don't have the message translation just replace it with a default message
          newErrors.push(translate('SOMETHING_WENT_WRONG'));
        } else {
          newErrors.push(errorMessage);
        }
      });
    }
    setFormErrors(newErrors);
  }, [error, translate]);

  const validateForm = useCallback(
    (formData: FormFields) => {
      setFormErrors([]);
      const newErrors: string[] = [];
      schedulePeriods.forEach(
        ({ id: periodId, key: periodKey, name: periodName, titleCode: periodTitleCode }) => {
          const resultName = periodName || translate(periodTitleCode);
          if (
            formData?.atc === SchedulingMode.Scheduled &&
            periodKey !== SchedulePeriodsTypes.ATC
          ) {
            if (!periodId) {
              newErrors.push(translate('PERIOD_HAS_NO_SCENARIOS', { periodName: resultName }));
            }
            if (!JSON.stringify(schedule).includes(periodKey)) {
              newErrors.push(translate('PERIOD_HAS_NO_SCHEDULE', { periodName: resultName }));
            }
          } else if (
            formData?.atc === SchedulingMode.ATC &&
            periodKey === SchedulePeriodsTypes.ATC
          ) {
            if (!periodId) {
              newErrors.push(translate('PERIOD_HAS_NO_SCENARIOS', { periodName: resultName }));
            }
          }
        }
      );
      // TODO: add tree traversal for this validation
      if (
        Object.keys(data?.destination || {}).some((scenarioKey: string) => {
          const {
            type,
            children = [],
            button,
          } = data?.destination?.[scenarioKey] || { children: [], button: undefined };
          return type === ScenariosTypes.ClientChoice && !children?.length && button !== '?';
        })
      ) {
        newErrors.push(translate('VOICE_MENU_NOT_PROPERLY_CONFIGURED'));
      }
      setFormErrors(newErrors);
      return newErrors;
    },
    [data?.destination, schedule, schedulePeriods, translate]
  );

  const changePeriod = useCallback(
    (item: ISchedulePeriod) => {
      setIsChanged({
        isBlocking: true,
        action: ConfirmAction.Edit,
      });
      const periodIndex = schedulePeriods.findIndex(({ key: periodKey }) => periodKey === item.key);
      if (periodIndex >= 0) {
        const newPeriods = [...schedulePeriods];
        newPeriods.splice(periodIndex, 1, item);
        setSchedulePeriods(newPeriods);
      }
    },
    [schedulePeriods, setSchedulePeriods, setIsChanged]
  );

  const addPeriod = useCallback(() => {
    setIsChanged({
      isBlocking: true,
      action: ConfirmAction.Edit,
    });
    const periodToAdd = customPeriods.find(
      ({ key: periodKey }) =>
        !schedulePeriods.some(({ key: existingKey }) => existingKey === periodKey)
    );
    if (periodToAdd !== undefined) {
      setSchedulePeriods((prevState) => [
        ...prevState,
        {
          ...periodToAdd,
          name: translate('PERIOD_X', { x: prevState.length - 1 }),
        } as ISchedulePeriod,
      ]);
      setActivePeriod(periodToAdd.key);
    }
    setSchedulerOpen(true);
  }, [schedulePeriods, translate, setIsChanged]);

  const removePeriod = useCallback(
    (e: MouseEvent<SVGElement>, periodKey: SchedulePeriodsTypes) => {
      e.preventDefault();
      e.stopPropagation();
      setIsChanged({
        isBlocking: true,
        action: ConfirmAction.Edit,
      });
      if (activePeriod === periodKey) {
        setActivePeriod(SchedulePeriodsTypes.WorkingHours);
      }
      setSchedule((prevState) =>
        [...prevState].map((day) => ({
          ...day,
          hours: day.hours.map((hour) => ({
            ...hour,
            minutes: hour.minutes.map((minute) =>
              minute !== periodKey ? minute : SchedulePeriodsTypes.NonWorkingHours
            ),
          })),
        }))
      );
      setSchedulePeriods((prevState) => prevState.filter(({ key }) => key !== periodKey));
    },
    [activePeriod, setIsChanged]
  );

  useEffect(() => {
    if (!loadingIncomingNumber && prevLoadingIncomingNumber && incomingNumber) {
      const { name, hasSchedule, destination: incomingNumberSettings = {} } = incomingNumber;
      let { periods: incomingNumberPeriods = [], scheduler: incomingNumberScheduler = {} } =
        incomingNumber;
      if (incomingNumberPeriods.length < 3) {
        incomingNumberPeriods = initPeriods(incomingNumberPeriods);
      }
      if (Object.keys(incomingNumberScheduler).length < incomingNumberPeriods.length - 1) {
        incomingNumberScheduler = initScheduler();
      }

      setSchedulePeriods(initPeriods(incomingNumberPeriods));
      reset({
        name: isCopyNumberSettings ? numberName : name,
        atc: Number(Boolean(!hasSchedule)) ? SchedulingMode.ATC : SchedulingMode.Scheduled,
      });
      setData({ ...incomingNumber, destination: incomingNumberSettings });
      setSchedule(deserializeSchedule(incomingNumberScheduler));
      setActivePeriod(
        Number(Boolean(!hasSchedule)) ? SchedulePeriodsTypes.ATC : SchedulePeriodsTypes.WorkingHours
      );
    }
  }, [
    setData,
    incomingNumber,
    loadingIncomingNumber,
    prevLoadingIncomingNumber,
    reset,
    isCopyNumberSettings,
    numberName,
  ]);

  const atc = watch('atc') || SchedulingMode.ATC;

  useEffect(() => {
    if (atc === SchedulingMode.Scheduled) {
      setActivePeriod(SchedulePeriodsTypes.WorkingHours);
    }
  }, [atc]);

  useFormErrors(undefined, formMethods);

  useEffect(() => {
    if (!Number.isFinite(Number(id))) {
      navigate('/calls/incoming');
    }
  }, [navigate, id]);

  const handleSubmitForm = (formData: FormFields) => {
    if (loading) return;

    const hasErrors = !!validateForm(formData).length;
    if (hasErrors) {
      return;
    }

    setIsChanged({
      isBlocking: false,
      action: ConfirmAction.Finish,
    });
    updateIncomingNumber({
      variables: {
        data: {
          id: Number(id),
          name: formData.name || '',
          hasSchedule: formData.atc !== SchedulingMode.ATC,
          periods: schedulePeriods,
          scheduler: serializeSchedule(schedule),
          destination: data?.destination || {},
        },
      },
      refetchQueries: [
        {
          query: INCOMING_NUMBER_QUERY,
          variables: {
            data: { id: Number.isFinite(Number(id)) ? Number(id) : undefined },
          },
        },
      ],
    });
  };

  const handleCopyNumberSettings = () => {
    if (selectedNumber) {
      refetch({
        data: { id: Number.isFinite(Number(selectedNumber)) ? Number(selectedNumber) : undefined },
      }).then((res) => {
        const formattedNumberObj: Omit<typeof res.data.getNumber, 'name'> & { name?: string } = {
          ...res.data?.getNumber,
          id: Number(id),
        };
        delete formattedNumberObj.name;

        setData(formattedNumberObj);
        setValue('name', numberName);
        setIsCopyNumberSettingsModalOpen(false);
        setIsCopyNumberSettings(true);
      });
    }
  };

  const handleAddNode = ({
    parentId,
    periodKey,
    node,
  }: {
    parentId?: string;
    periodKey?: SchedulePeriodsTypes;
    node: IIncomingNumberScenario;
  }) => {
    setIsChanged({
      isBlocking: true,
      action: ConfirmAction.Edit,
    });
    const { id: nodeId = Math.random().toString(36).substr(2, 9), ...rest } = node || {
      type: ScenariosTypes.Message,
    };
    setData((prevState) => {
      const newNodeChanges = { [nodeId]: { id: nodeId, ...rest } };
      const foundPeriod = schedulePeriods.find(({ key }) => periodKey === key);
      if (foundPeriod) {
        changePeriod({ ...foundPeriod, id: nodeId });
      }
      const prevSettings = prevState?.destination || {};
      const prevParent: IIncomingNumberScenario =
        parentId && prevSettings?.[parentId]
          ? (prevSettings?.[parentId] as IIncomingNumberScenario)
          : { type: ScenariosTypes.Message };
      const { children: prevParentChildren = [] } = prevParent;
      const parentNodeChanges = parentId
        ? {
            [parentId]: {
              ...prevParent,
              children: [...prevParentChildren, nodeId],
            },
          }
        : {};
      return {
        ...prevState,
        destination: {
          ...prevSettings,
          ...parentNodeChanges,
          ...newNodeChanges,
        },
      };
    });
  };

  const handleChangeNode = ({
    nodeId,
    node,
  }: {
    parentId?: string;
    nodeId: string;
    node: IIncomingNumberScenario;
  }) => {
    setIsChanged({
      isBlocking: true,
      action: ConfirmAction.Edit,
    });
    setData((prevState: Partial<IncomingNumberData> | undefined = {}) => {
      const { destination: prevSettings = {} } = prevState;
      const prevNode = prevState?.destination?.[nodeId] || {};
      const nodeChanges = { [nodeId]: { ...prevNode, ...node } };
      return {
        ...prevState,
        destination: {
          ...prevSettings,
          ...nodeChanges,
        },
      };
    });
  };

  const handleRemoveNode = ({
    parentId,
    periodKey,
    nodeId,
  }: {
    parentId?: string;
    periodKey?: SchedulePeriodsTypes;
    nodeId: string;
  }) => {
    setIsChanged({
      isBlocking: true,
      action: ConfirmAction.Edit,
    });
    if (periodKey) {
      const foundPeriod = schedulePeriods.find(({ key }) => periodKey === key);
      if (foundPeriod) {
        changePeriod({ ...foundPeriod, id: undefined });
      }
    }

    setData((prevState: any = {}) => {
      const { destination: prevSettings = {} } = prevState;
      const prevParent: IIncomingNumberScenario =
        parentId && prevSettings?.[parentId] !== undefined
          ? (prevSettings?.[parentId] as IIncomingNumberScenario)
          : { type: ScenariosTypes.Message };
      const { children: prevParentChildren = [] } = prevParent;
      const parentNodeChanges = parentId
        ? {
            [parentId]: {
              ...prevParent,
              children: prevParentChildren.filter((childrenId: string) => childrenId !== nodeId),
            },
          }
        : {};
      const nodeChanges = { [nodeId]: undefined };
      return {
        ...prevState,
        destination: {
          ...prevSettings,
          ...parentNodeChanges,
          ...nodeChanges,
        },
      };
    });
  };

  const renderSettingsEditor = () => {
    if (scenarioConfig) {
      return (
        <IncomingNumberScenarios
          {...(scenarioConfig || {})}
          settings={data?.destination || {}}
          onChange={handleChangeNode}
          onAdd={handleAddNode}
          onRemove={handleRemoveNode}
          onClose={() => setScenarioConfig(undefined)}
        />
      );
    }
    return null;
  };

  const renderSchedule = () => {
    if (atc === SchedulingMode.Scheduled) {
      return (
        <>
          <div className={classes.collapseHeader}>
            <SchedulerPeriods
              activePeriod={activePeriod}
              setActivePeriod={(period) => {
                setActivePeriod(period);
                setSchedulerOpen(true);
              }}
              periods={schedulePeriods}
              addPeriod={addPeriod}
              removePeriod={removePeriod}
              changePeriod={changePeriod}
            />
          </div>
          <Scheduler
            schedule={schedule}
            setSchedule={setSchedule}
            onScheduleChanged={handleScheduleChanges}
            activePeriod={activePeriod}
            availablePeriods={schedulePeriods}
          />
        </>
      );
    }
    return null;
  };

  const renderNumberIntegrations = () => {
    if (!incomingNumber || !incomingNumber.integrations?.length) return null;
    const { integrations: integrationList } = incomingNumber;
    const statusOrder = [
      IntegrationStatus.Active,
      IntegrationStatus.Draft,
      IntegrationStatus.Inactive,
    ];
    integrationList?.sort((a, b) => statusOrder.indexOf(a.status) - statusOrder.indexOf(b.status));
    const responsibleSetup =
      integrationList?.find((integration) => integration.id === incomingNumber.responsible?.id) ||
      null;
    const dividerIndex = integrationList?.filter(
      (integration) =>
        integration.status === IntegrationStatus.Active ||
        integration.status === IntegrationStatus.Draft
    ).length;

    return (
      <Flex className={classes.integrationResponsible} direction={'column'}>
        <Typography type={'text2'} color={'tertiary900'}>
          {translate('INTEGRATIONS')}
        </Typography>
        <Typography className={classes.title} type={'text3'} color={'tertiary900'}>
          {translate('INCOMING_NUMBER_INTEGRATION_MESSAGE')}
        </Typography>
        <Flex className={classes.integrationWrapper} direction={'row'}>
          {integrationList?.map((integration, index) => {
            const IntegrationLogo = integrationsLogoList[integration.type] || RESTIconSmall;
            const isDisabled = integration.status === IntegrationStatus.Inactive;
            return (
              <>
                {dividerIndex !== 0 && dividerIndex === index ? (
                  <div className={classes.integrationListDivider} />
                ) : null}
                <div
                  className={classes.integrationIconWrapper}
                  key={`${integration.name}-${index}`}
                >
                  <Flex
                    className={clsx(classes.integrationIcon, {
                      [classes.integrationIconDisabled]: isDisabled,
                    })}
                    alignItems="center"
                    justifyContent="center"
                  >
                    <IntegrationLogo />
                  </Flex>
                  {isDisabled ? (
                    <Flex
                      className={classes.integrationDisabledIcon}
                      alignItems="center"
                      justifyContent="center"
                    >
                      <PowerIcon />
                    </Flex>
                  ) : null}
                </div>
              </>
            );
          })}
        </Flex>
        {responsibleSetup && responsibleSetup.status !== IntegrationStatus.Inactive ? (
          <>
            <Typography className={classes.title} type={'text3'} color={'tertiary900'}>
              {translate('REDIRECT_TO_RESPONSIBLE_INTEGRATION_MESSAGE', {
                name: responsibleSetup.name,
              })}
            </Typography>
            <Flex className={classes.responsibleSettingText}>
              <ScenarioResponsibleLine
                size={'text3'}
                color={'tertiary900'}
                responsibleData={responsibleSetup?.settings}
              />
            </Flex>
            <Typography type={'text3'} color={'tertiary900'}>
              <Button clear onClick={() => navigate('/integrations')}>
                <Typography type={'text3'} color={'link600'} underline>
                  {translate('CONFIGURE')}
                </Typography>
              </Button>
            </Typography>
          </>
        ) : (
          <Typography className={classes.title} type={'text3'} color={'tertiary900'}>
            <Typography type={'text3'}>
              {translate('REDIRECT_TO_RESPONSIBLE_EMPTY_MESSAGE')}{' '}
            </Typography>{' '}
            <Button clear onClick={() => navigate('/integrations')}>
              <Typography type={'text3'} color={'link600'} underline>
                {translate('CONFIGURE')}
              </Typography>
            </Button>
          </Typography>
        )}
      </Flex>
    );
  };

  const renderCustomOption = ({
    data: numberData,
    selected,
    content,
  }: ISelectFieldOptionRendererProps<{ id: string | number; phone: string; name: string }>) => {
    const { id: numId, phone = '', name = '' } = numberData;
    if (numId === undefined) {
      return (
        <div className={classes.customOption}>
          <Typography type={'text3'} color={'tertiary300'}>
            {translate('CHOOSE_NUMBER')}
          </Typography>
        </div>
      );
    }

    return (
      <div className={classes.customOption}>
        <Flex alignItems={'center'}>
          {selected && <CheckIcon className={classes.glyph} />}
          <Flex
            className={clsx(!selected && !content && classes.customOptionFlexMarginLeft)}
            direction={'column'}
          >
            <Typography type={'text3'} color={selected ? 'primary700' : 'tertiary900'}>
              {formatPhone(phone)}
            </Typography>
            {name && (
              <Typography className={classes.numberName} type={'text4'} color={'tertiary600'}>
                {name}
              </Typography>
            )}
          </Flex>
        </Flex>
      </div>
    );
  };

  const renderIncomingNumbersContent = () => {
    if (loadingIncomingNumber) return <PagePreloader />;
    return (
      <>
        <Flex direction={'column'} className={classes.root}>
          <FormProvider {...formMethods}>
            <form
              id={'edit-incoming-number'}
              action={''}
              onSubmit={handleSubmit(handleSubmitForm)}
              className={classes.form}
            >
              <FormErrors className={classes.formErrors} errors={errors} />
              {domainNumbers.length > 0 && (
                <Flex className={classes.marginTop1} justifyContent={'spaceBetween'} fullWidth>
                  <Typography type={'text2'} color={'tertiary900'} className={classes.title}>
                    {translate('NUMBER_NAME')}
                  </Typography>
                  <Flex className={classes.marginTop05} alignItems={'center'}>
                    <Button
                      className={classes.copyBtn}
                      variant={'secondary'}
                      onClick={() => setIsCopyNumberSettingsModalOpen(true)}
                    >
                      <Typography className={classes.copyText}>{translate('REPLICATE')}</Typography>
                      <DocumentArrowIcon />
                    </Button>
                  </Flex>
                </Flex>
              )}
              <div className={classes.formControl}>
                <FormFieldRhfUncontrolled name={'name'} />
              </div>
              {renderNumberIntegrations()}
              <Typography type={'text2'} color={'tertiary900'}>
                {translate('SCHEDULE')}
              </Typography>
              <div className={classes.formControl}>
                <SelectField
                  name={'atc'}
                  valueKey={'value'}
                  titleKey={'titleCode'}
                  translating
                  data={SchedulingModesOptions}
                  onChange={() =>
                    setIsChanged({
                      isBlocking: true,
                      action: ConfirmAction.Edit,
                    })
                  }
                />
              </div>
              {renderSchedule()}
              <div className={classes.section}>
                <Typography className={classes.marginBottom5} type={'text2'} color={'tertiary900'}>
                  {translate('INCOMING_CALLS_SCENARIOS')}
                </Typography>
              </div>
              <IncomingNumberScenariosTree
                mode={atc || SchedulingMode.ATC}
                items={data?.destination || {}}
                onOpenEditor={setScenarioConfig}
                periods={schedulePeriods}
              />
              <BottomButtons>
                <div className={classes.actions}>
                  <ControlButtons
                    confirmTitle={'SAVE_CHANGES'}
                    cancelTitle={'CANCEL'}
                    form={'edit-incoming-number'}
                    onCancelClick={handleCancelChanges}
                    loading={loading}
                    rootConfirmStyles={classes.confirmButton}
                  />
                </div>
              </BottomButtons>
              <FormErrorMessage errors={undefined} />
              <MessageDialog
                isOpen={isCopyNumberSettingsModalOpen}
                title={translate('QUICK_SETUP')}
                onCancel={handleCloseCopyNumberSettingsDialog}
                contentClass={classes.copyDialog}
                renderContent={
                  <>
                    <div className={classes.copyDialogText}>
                      <Typography type={'text3'} color={'tertiary900'}>
                        {translate('SELECT_NUMBER_FOR_COPY')}
                      </Typography>
                    </div>
                    <SelectField
                      fullWidth
                      label={translate('NUMBER')}
                      name={'copiedId'}
                      data={domainNumbers}
                      Renderer={renderCustomOption}
                      valueKey={'id'}
                      titleKey={'phone'}
                      validate={(value) => {
                        if (!value && value !== 0) {
                          return translate('CHOOSE_NUMBER') as string;
                        }
                        return true;
                      }}
                      onChange={handleScheduleChanges}
                    />
                    <ControlButtons
                      confirmTitle={'COPY'}
                      cancelTitle={'CANCEL'}
                      cancelVariant="secondary"
                      onCancelClick={handleCloseCopyNumberSettingsDialog}
                      justifyContent={'start'}
                      flexDirection={'row-reverse'}
                      rootClass={classes.actionsBtns}
                      onConfirmClick={handleSubmit(handleCopyNumberSettings)}
                      small
                    />
                  </>
                }
              />
            </form>
            <ConfirmDialog
              isBlocked={isChanged.isBlocking}
              onNavigationBlocked={getBlockedPath}
              onSaveChanges={handleSubmit(handleSubmitForm)}
            />
          </FormProvider>
        </Flex>
        {renderSettingsEditor()}
      </>
    );
  };

  return (
    <BodyContainer customRootClass={classes.contentBottomSpace}>
      {renderIncomingNumbersContent()}
    </BodyContainer>
  );
};

export default IncomingNumber;
