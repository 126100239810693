import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ArrowBackIcon,
  ArrowUpIcon,
  DownloadIcon,
  RubleIcon,
  InfoIcon,
  ChevronDownIcon,
  ChevronUpIcon,
} from '@shared/assets/images/icons';
import { createSearchParams, NavLink, useNavigate } from 'react-router-dom';
import { privateRoutesPropsMap } from '@/routes';
import { AUTHENTICATION_STATE_QUERY } from '@components/client/queries';
import { BALANCE_QUERY, INCOMING_NUMBER_PHONE_QUERY, USER_QUERY } from '@/client/queries';
import { useMutation, useQuery } from '@apollo/client';
import Button from '@shared/components/Button';
import Drawer from '@material-ui/core/Drawer';
import Typography from '@shared/components/Typography';
import clsx from 'clsx';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { toPrecision, formatPhone } from '@components/utils';
import { useRoutes } from '@components/Routes';
import { isFeatureAvailable, useDomainFeatures } from '@/common/hooks';
import { DomainStatus, IDomain } from '@components/typings/interfaces';
import { getCurrentDomain, getRole, hasFinancialRole, hasSystemRole } from '@/utils';
import Preloader from '@shared/components/Preloader';
import Flex from '@shared/components/Flex';
import Avatar from '@shared/components/Avatar';
import { GET_ACCESS_TO_DOMAIN_MUTATION } from '@/client/mutations';
import { useDashboardHeaderStyles } from './DashboardLayout.styles';
import { menuItems, sysUserMenuNavItems, userMenuNavItems } from './DashboardHeader.constants';

export const DashboardHeader = () => {
  const { data: userData, loading } = useQuery(USER_QUERY, { fetchPolicy: 'cache-first' });

  const { data: dataAuthStateQuery } = useQuery<{
    role?: string;
  }>(AUTHENTICATION_STATE_QUERY);
  const role = getRole(dataAuthStateQuery?.role);

  const [openMenu, setMenuOpen] = useState(false);
  const {
    data: balanceData,
    error: balanceError,
    loading: balanceLoading,
  } = useQuery(BALANCE_QUERY, { skip: !openMenu });

  const balance = balanceData?.getBalance.balance || 0;
  const credit = balanceData?.getBalance.credit || 0;

  const currentDomain = useMemo(() => getCurrentDomain(userData?.user), [userData?.user]);
  const account = useMemo(() => currentDomain?.client?.account, [currentDomain]);
  const status = currentDomain?.status || DomainStatus.Unknown;
  const [getAccessToDomain] = useMutation(GET_ACCESS_TO_DOMAIN_MUTATION);

  const [translate] = useTranslation();
  const navigate = useNavigate();
  const classes = useDashboardHeaderStyles();

  const { route, path } = useRoutes(privateRoutesPropsMap);
  const [title, setTitle] = useState<string>(translate(route?.titleCode || ''));
  const [isProfileBalloonOpen, setIsProfileBalloonOpen] = useState(false);

  const docsFlags = userData?.user.domains.find((domain) => domain.current)?.params?.docs;

  useQuery(INCOMING_NUMBER_PHONE_QUERY, {
    variables: { data: { id: parseInt(path.identifier || '0', 10) } },
    fetchPolicy: 'cache-and-network',
    skip: !(path.category === 'calls' && path.subcategory === 'incoming' && path.identifier),
    onCompleted: (data) => {
      if (data?.getNumber?.phone) {
        const titleSuffix = formatPhone(data?.getNumber?.phone);
        setTitle(`${translate(route?.titleCode || '')} ${titleSuffix}`);
      }
    },
  });

  const { features } = useDomainFeatures();

  const isDocumentsAvailable = useMemo(() => isFeatureAvailable('documents', features), [features]);

  const financialMenuAvailable = useMemo(() => hasFinancialRole(role), [role]);
  const isSystemUser = useMemo(() => hasSystemRole(role), [role]);

  useEffect(() => {
    setTitle(`${translate(route?.titleCode || '')}`);
  }, [route, translate]);

  function handleMenuOpen() {
    setMenuOpen(true);
  }

  function handleMenuClickOutside() {
    setMenuOpen(false);
  }

  function handleUserBalloonClickOutside() {
    setIsProfileBalloonOpen(false);
  }

  function handlePBXLogin(domain: IDomain) {
    getAccessToDomain({ variables: { domainId: domain.id } }).then(({ data }) => {
      if (data && data?.getAccessToDomain?.token) {
        const params = createSearchParams({
          token: data?.getAccessToDomain?.token,
          refreshToken: data?.getAccessToDomain?.refreshToken,
        });
        window.open(
          `${
            process.env.NODE_ENV === 'production'
              ? `https://${domain.domain}`
              : `http://${process.env.HOST}:${process.env.PORT}`
          }?${params}`,
          '_blank',
          'noopener'
        );
      }
    });
  }

  const getAvatarUrl = (avatar?: string | null): string | undefined => {
    if (avatar) {
      return `${process.env.API_URL}/files/avatar/${avatar}`;
    }
    return undefined;
  };

  const renderUserData = () => {
    if (loading) {
      return <Preloader size={'medium'} />;
    }
    if (userData?.user) {
      const { name = '', email = '', avatar = '' } = userData?.user || {};
      return (
        <>
          <Flex direction={'column'} className={classes.userNameWrapper}>
            <Typography className={classes.textOverflow} type={'text3'} color={'tertiary900'}>
              {name || email || 'profile'}
            </Typography>
            <Typography
              type={'text4'}
              color={isSystemUser ? 'primary600' : 'tertiary600'}
              bold={isSystemUser}
            >
              {currentDomain?.domain || ''}
            </Typography>
          </Flex>
          {!isSystemUser && (
            <Avatar
              url={getAvatarUrl(avatar)}
              className={classes.userAvatar}
              name={name || undefined}
              showInitials
              size={'small'}
            />
          )}
          {!isProfileBalloonOpen ? (
            <ChevronDownIcon className={classes.marginLeft05} />
          ) : (
            <ChevronUpIcon className={classes.marginLeft05} />
          )}
        </>
      );
    }
    return null;
  };

  const renderMenuItems = () => {
    return menuItems
      .filter((item) => {
        if (item.availableIn && !item.availableIn.includes(status)) {
          return false;
        }
        if (item.notAvailableIn && item.notAvailableIn.includes(status)) {
          return false;
        }
        if (item.feature && !isFeatureAvailable(item.feature, features)) {
          return false;
        }
        return true;
      })
      .map((item, index) => (
        <div className={classes.linkWrapper} key={index}>
          <NavLink
            onClick={() => setMenuOpen(false)}
            to={item.path}
            className={classes.link}
            key={index}
          >
            <div className={classes.iconWrapper}>{item.icon}</div>
            <Typography type={'text3'} color={'base'}>
              {translate(item.title)}
            </Typography>
          </NavLink>
        </div>
      ));
  };

  const renderUserMenuBalloon = () => {
    const { domains = [] } = userData?.user || {};
    if (isProfileBalloonOpen) {
      return (
        <ClickAwayListener onClickAway={handleUserBalloonClickOutside}>
          <div
            className={clsx(classes.userMenuBalloonWrapper, {
              [classes.userMenuWithFinancialMenu]: financialMenuAvailable,
              [classes.userMenuWithoutFinancialMenu]: !financialMenuAvailable,
            })}
          >
            <Flex className={classes.userInfoContainer}>
              <Typography className={classes.userInfoText} type={'text4'} color={'tertiary600'}>
                {`${translate('CURRENT_PBX')}:`}
              </Typography>
              <Typography className={classes.userInfoText} type={'text3'} color={'link600'} medium>
                {userData?.user?.domains.map((el) => !!el.current && el.domain)}
              </Typography>
            </Flex>
            {!isSystemUser && domains.length > 1 && (
              <Flex className={classes.userAvailableAtcContainer} direction={'column'}>
                <Typography
                  className={classes.userAvailableAtsTitle}
                  type={'text4'}
                  color={'tertiary600'}
                >
                  {`${translate('AVAILABLE_PBX')}:`}
                </Typography>
                {domains
                  .filter((domain) => domain.id !== currentDomain?.id)
                  .map((domain, index) => (
                    <Button
                      clear
                      className={classes.userBalloonItemRow}
                      key={`available-ats-${index}`}
                      onClick={() => handlePBXLogin(domain)}
                    >
                      <DownloadIcon className={classes.userBalloonAvailableIcon} />
                      <Typography
                        className={classes.userDomain}
                        type={'text3'}
                        color={'tertiary900'}
                      >
                        {domain.domain}
                      </Typography>
                    </Button>
                  ))}
                {/*
                //TODO Заготовка для кнопки добавить ATC
                <Typography className={classes.userAddAtsBtn}>{translate('ADD_PBX')}</Typography>
                */}
              </Flex>
            )}
            <Flex direction={'column'}>
              <Flex className={classes.userBalloonItemRows}>
                {(isSystemUser ? sysUserMenuNavItems : userMenuNavItems).map((item, i) => (
                  <Button
                    clear
                    onClick={() => {
                      if (item.external) {
                        window.open(item.path);
                        return;
                      }
                      navigate(item.path);
                    }}
                    className={classes.userBalloonItemRow}
                    key={`user-menu-item-${i}`}
                  >
                    <div
                      className={clsx(classes.userBalloonIcon, {
                        [classes.userBalloonLogoutIcon]: !!item.logOut,
                      })}
                    >
                      {item.icon}
                    </div>
                    <Typography type={'text3'} color={item.logOut ? 'danger600' : 'tertiary900'}>
                      {translate(item.title)}
                    </Typography>
                  </Button>
                ))}
              </Flex>
            </Flex>
          </div>
        </ClickAwayListener>
      );
    }
    return null;
  };

  const renderMessageType = () => {
    if (!isDocumentsAvailable) {
      return <div />;
    }
    if (status === DomainStatus.Demo) {
      return (
        <div className={classes.menuBottomContainerDemo}>
          <Typography type={'text4'} color={'tertiary900'}>
            {translate('CONTRACT_DESCRIPTION')}
          </Typography>
          <ArrowUpIcon className={classes.menuArrowDown} />
          <Button
            title={
              docsFlags?.passport ? translate('CONTINUE_CONTRACT') : translate('EXECUTE_CONTRACT')
            }
            className={classes.button}
            onClick={() => {
              navigate('/register/contract');
            }}
            variant={'secondary'}
          />
          <a
            rel={'noreferrer'}
            target={'_blank'}
            href={'/Договор оферты.pdf'}
            className={classes.downloadTemplateLink}
          >
            <DownloadIcon style={{ fill: '#0073FA' }} />
            <Typography type={'text3'} color={'link600'} underline>
              {translate('DOWNLOAD_CONTRACT_TEMPLATE')}
            </Typography>
          </a>
        </div>
      );
    }
    if (status === DomainStatus.Confirmed) {
      return (
        <div className={classes.menuBottomContainer}>
          <Typography type={'text3'} color={'tertiary900'}>
            {translate('CONTRACT_DESCRIPTION_CONFIRMED')}
          </Typography>
        </div>
      );
    }
    if (status === DomainStatus.Moderating) {
      return (
        <div className={classes.menuBottomContainer}>
          <Typography type={'text4'} color={'base'}>
            {translate('CONTRACT_DESCRIPTION_MODERATING')}
          </Typography>
        </div>
      );
    }
    return <div />;
  };

  return (
    <header className={classes.root}>
      <div className={classes.header}>
        <div className={classes.backTitleBlock}>
          <Button onClick={() => navigate(-1)} className={classes.backButton} variant="text">
            <ArrowBackIcon />
          </Button>
          <Typography color={'tertiary900'} type={'text2'}>
            {title}
          </Typography>
        </div>
        <div className={classes.actions}>
          <div
            className={classes.userDataBlockWrapper}
            onClick={() => setIsProfileBalloonOpen(true)}
          >
            {renderUserData()}
            {renderUserMenuBalloon()}
          </div>
          {financialMenuAvailable && (
            <ClickAwayListener onClickAway={handleMenuClickOutside}>
              <div>
                <div className={classes.buttonMenuIcon} onClick={handleMenuOpen}>
                  <RubleIcon className={classes.buttonIcon} />
                </div>
                <Drawer
                  variant={'temporary'}
                  anchor={'right'}
                  open={openMenu}
                  classes={{ paper: classes.rightMenu }}
                  onClose={() => setMenuOpen(false)}
                >
                  <div
                    className={clsx(classes.menuBackground, {
                      [classes.menuBackgroundDemo]:
                        status === DomainStatus.Demo || status === DomainStatus.Moderating,
                    })}
                  >
                    <div className={classes.menuTopContainer}>
                      <Typography type={'text2'} color={'base'}>
                        {translate('FINANCIAL_CABINET_LABEL')}
                      </Typography>
                      <div className={classes.menuItems}>{renderMenuItems()}</div>
                    </div>
                    <div className={classes.menuAccountContainer}>
                      <Typography type={'text3'} color={'base'} medium>
                        {`${translate('PBX_ACCOUNT')}: ${account}`}
                      </Typography>
                    </div>
                    <div
                      className={
                        balanceError ? classes.menuMidContainerWarning : classes.menuMidContainer
                      }
                    >
                      {/* eslint-disable-next-line no-nested-ternary */}
                      {balanceError ? (
                        <div className={classes.balanceUnavailable}>
                          <Typography type={'text3'} color={'base'}>
                            {translate('BALANCE_UNAVAILABLE')}
                          </Typography>
                          <InfoIcon className={classes.infoIcon} />
                        </div>
                      ) : balanceLoading ? (
                        <Preloader size={'medium'} />
                      ) : (
                        <>
                          <Typography type={'text3'} color={'base'}>
                            {translate('BALANCE')}
                          </Typography>
                          <div className={classes.menuBalanceLine}>
                            <Typography
                              type={'text2'}
                              color={'base'}
                              medium
                              className={classes.menuBalanceNumber}
                            >
                              {toPrecision(balance)} ₽
                            </Typography>
                            {credit !== 0 && (
                              <Typography
                                className={classes.menuBalanceNumberCredit}
                                type={'text3'}
                                color={'base'}
                              >
                                {translate('CREDIT_AMOUNT', { amount: toPrecision(credit) })}
                              </Typography>
                            )}
                          </div>
                        </>
                      )}
                    </div>
                    {renderMessageType()}
                  </div>
                </Drawer>
              </div>
            </ClickAwayListener>
          )}
        </div>
      </div>
    </header>
  );
};

export default DashboardHeader;
