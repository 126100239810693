import { makeStyles } from '@material-ui/core';

export const useFormFieldUIStyles = makeStyles(
  ({ color: { tertiary, danger, base, primary } }) => ({
    adornedEnd: {
      paddingRight: 0,
    },
    adornedStart: {
      paddingLeft: 0,
    },
    inputMarginDense: {
      padding: '0.75em',
    },
    inputAdornedEnd: {
      paddingRight: '3em',
    },
    inputAdornedStart: {
      paddingLeft: '3em',
    },
    input: {
      fontSize: '0.875em',
      backgroundColor: base,
      boxSizing: 'border-box',
      height: '100%',
      '&::-ms-reveal': {
        display: 'none',
      },
      '&::-ms-clear': {
        display: 'none',
      },
      '&::placeholder': {
        opacity: 1,
        color: tertiary['400'],
      },
      '&:read-only': {
        backgroundColor: tertiary['100'],
        borderRadius: '4px',
        '&:not(.MuiSelect-root):not([aria-hidden=true]) ~ fieldset': {
          border: 'none',
        },
        '&.MuiSelect-root': {
          backgroundColor: base,
        },
      },
    },
    root: {
      width: '100%',
      maxWidth: '100%',
      minWidth: '6.25em',
      margin: 'initial',
      borderWidth: '1px',
      borderRadius: '4px',
      '& $notchedOutline': {
        borderWidth: '1px',
        borderColor: tertiary['300'],
      },
      '&:hover:not($disabled):not($error)': {
        '& $notchedOutline': {
          borderColor: tertiary['500'],
        },
        '& $focused:not($disabled):not($error)': {
          '& $notchedOutline': {
            borderWidth: '1px',
            borderColor: primary['700'],
          },
        },
        '& $disabled': {
          '& $notchedOutline': {
            borderColor: tertiary['300'],
          },
        },
        '& $error': {
          '& $notchedOutline': {
            borderWidth: '1px',
            borderColor: danger['600'],
          },
        },
      },
      '& $focused:not($disabled):not($error)': {
        '& $notchedOutline': {
          borderWidth: '1px',
          borderColor: primary['700'],
        },
      },

      '& $error': {
        '&:focused': {
          '& $notchedOutline': {
            borderWidth: '1px',
            borderColor: danger['600'],
          },
        },
        '& $notchedOutline': {
          borderWidth: '1px',
          borderColor: danger['300'],
        },
      },
    },
    multiline: {
      '&$marginDense': {
        transform: 'none',
        lineHeight: '1.5em',
        padding: 0,
        height: 'auto',
        '& textarea': {
          padding: '0.822em 1em',
        },
      },
    },
    focused: {
      '&.MuiOutlinedInput-colorSecondary .MuiOutlinedInput-notchedOutline': {
        borderColor: 'transparent',
      },
      '&.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
        borderColor: primary['700'],
      },
    },
    disabled: {},
    error: {},
    notchedOutline: {},
    marginDense: {},
  }),
  {
    name: 'field',
    index: 1,
  }
);

export const useAdornmentStyles = makeStyles(
  {
    root: {
      position: 'absolute',
      display: 'flex',
      width: '100%',
    },
    positionEnd: {
      flexDirection: 'row-reverse',
      right: '1em',
    },
    positionStart: {
      flexDirection: 'row',
      left: '1em',
    },
  },
  {
    index: 1,
  }
);

export const useHelperTextStyles = makeStyles(
  ({ color: { tertiary, danger } }) => ({
    root: {
      margin: 0,
      position: 'absolute',
      top: '100%',
      fontSize: '0.75em',
      color: tertiary[600],
      '&$error': {
        color: danger[600],
      },
    },
    error: {},
  }),
  {
    index: 1,
  }
);

export const useLabelStyles = makeStyles(
  ({ color: { tertiary } }) => ({
    focused: {},
    shrink: {},
    marginDense: {},
    root: {
      position: 'relative',
      transform: 'none',
      padding: '0 0 1em',
      color: tertiary[700],
      fontSize: '0.75em',
      '&$focused': {
        color: tertiary['700'],
      },
      '&.Mui-error': {
        color: tertiary['700'],
      },
    },
    outlined: {
      '&$shrink': {
        transform: 'none',
      },
      '&$marginDense': {
        transform: 'none',
      },
    },
  }),
  {
    index: 1,
  }
);
