import React, { FunctionComponent, memo, useContext, useMemo } from 'react';
import Button from '@shared/components/Button';
import clsx from 'clsx';
import Typography from '@shared/components/Typography';
import Flex from '@shared/components/Flex';
import { FormControlLabel } from '@material-ui/core';
import Radio from '@shared/components/Radio';
import { CheckIcon, PointIcon } from '@shared/assets/images/icons';
import { useTranslation } from 'react-i18next';
import RadioGroup from '@shared/components/RadioGroup';
import { getSettingsObjectKeys, scenarioTabList } from '../../IntegrationForm.constants';
import { useIntegrationFormStyles } from '../../IntegrationForm.styles';
import ScenarioNumbersList from '../ScenarioNumbersList';
import {
  amoSettingLeadType,
  bitrixSettingLeadType,
  IScenariosActiveSettingsTabType,
  ISettingInstalledProps,
  optionSettingType,
  scenarioDirectionEnum,
  scenarioSettingsType,
  scenarioType,
  selectedNumbersType,
} from '../../IntegrationForm.interfaces';
import { useScenarioPropsHooks } from '../../hooks/useScenarioProps.hooks';
import ScenarioResponsibleLine from './ScenarioResponsibleLine';
import ScenarioSettingsLine from './ScenarioSettingsLine';
import { IntegrationContext } from '../../IntegrationForm';

export const ScenariosActiveSettingsTab: FunctionComponent<IScenariosActiveSettingsTabType> = ({
  onScenarioDelete,
  onTabChange,
  onOtherScenarioEdit,
  onScenarioEdit,
  onPageDataChange,
  onNumbersChange,
}) => {
  const classes = useIntegrationFormStyles();
  const {
    APIName,
    integrationId,
    integrationSettings,
    scenarioIndex,
    isPreview,
    isScenarioTabsAvailable,
    isSettingsAvailable,
    scenarioNumbers,
    availableDomainNumbers,
    selectedNumbers,
    scenarioData,
    responsibleData,
    rawEmployee,
    isEmployeeListAvailable,
  } = useContext(IntegrationContext)!; // TODO make null check
  const [translate] = useTranslation();
  const {
    isSimpleScenario,
    scenarioActiveTab,
    scenarioInformationTabs,
    setScenarioActiveTab,
    isBitrix24Integration,
    isGravitelIntegration,
    isAPIIntegration,
    isAmoCRMIntegration,
    isRoistatIntegration,
    isSimpleCallsIntegration,
    crmName,
  } = useScenarioPropsHooks(APIName);

  const filteredScenarioTabs = useMemo(() => {
    if (isSimpleScenario) {
      return scenarioTabList.filter((i) => i.scenarioType === scenarioType.Simple);
    }
    return scenarioTabList;
  }, [isSimpleScenario]);

  function handleScenarioNumbersChange(data: selectedNumbersType) {
    onNumbersChange(data);
  }

  function handlePageDataChange() {
    onPageDataChange();
  }

  function handleTabChange(index: number) {
    setScenarioActiveTab(index);
    onTabChange();
  }

  const renderScenarioTabsContent = () => {
    const renderScenarioTabs = (activeTab: number) => {
      if (isRoistatIntegration) {
        return (
          <Flex className={classes.scenarioTabTitle} direction={'column'}>
            <Typography type={'text3'} color={'tertiary900'} bold>
              {translate('ROISTAT_SETTINGS_BLOCK_TITLE')}
            </Typography>
            <Typography type={'text4'} color={'tertiary800'}>
              {translate('ROISTAT_SETTINGS_BLOCK_DESCRIPTION')}
            </Typography>
          </Flex>
        );
      }
      return filteredScenarioTabs.map(({ tabTitleDefault, tabTitleAdvance }, index) => (
        <Button
          key={`tab-${index}`}
          disableElevation
          disableRipple
          className={clsx(classes.scenarioTab, {
            [classes.scenarioTabActive]: activeTab === index,
          })}
          onClick={() => handleTabChange(index)}
        >
          <Typography type={'text3'} color={'tertiary900'}>
            {translate(isSimpleScenario ? tabTitleDefault : tabTitleAdvance)}
          </Typography>
        </Button>
      ));
    };

    const renderMainScenarioTabContent = (tabIndex: number) => {
      const scenario = scenarioInformationTabs?.[tabIndex];
      if (!scenario) {
        return null;
      }
      if (tabIndex === 0 && (scenario[0].active || integrationId) && !isPreview) {
        return (
          <div className={classes.integrationNumberTabsContent}>
            <Flex direction={'column'}>
              <FormControlLabel
                value={'allNumbers'}
                control={
                  <Radio
                    disabled={
                      scenarioIndex !== 0 || integrationSettings.length > 1 || !isSettingsAvailable
                    }
                    color={'secondary'}
                    onChange={handlePageDataChange}
                  />
                }
                label={translate('ALL_SCENARIO_NUMBERS')}
              />
              <FormControlLabel
                value={'selectedNumbers'}
                control={
                  <Radio
                    color={'secondary'}
                    disabled={!isSettingsAvailable}
                    onChange={handlePageDataChange}
                  />
                }
                label={translate('WITH_SELECTED_SCENARIO_NUMBERS')}
              />
            </Flex>
            {scenarioNumbers === 'selectedNumbers' && (
              <ScenarioNumbersList
                availableDomainNumbers={availableDomainNumbers}
                selectedNumbers={selectedNumbers}
                onNumbersChange={handleScenarioNumbersChange}
              />
            )}
          </div>
        );
      }
      return scenario.map((scenarioItem, scenarioItemIndex) => {
        const isScenarioAlwaysOn = scenarioItem.edit === scenarioSettingsType.Always;
        const isResponsibleScenario = scenarioItem.edit === scenarioSettingsType.Responsible;
        const isInformationDialog = scenarioItem.edit === scenarioSettingsType.CommonCallBack;
        const { direction, status, type } = getSettingsObjectKeys(scenarioItem.edit);

        const isSettingOn = () => {
          if (scenarioData) {
            if (direction === scenarioDirectionEnum.Common) {
              const isCommonSettingExist = scenarioData[direction];
              return isCommonSettingExist && isCommonSettingExist.defaultEmployee
                ? {
                    0: {
                      value: isCommonSettingExist.defaultEmployee || 'on',
                      type: optionSettingType.Common,
                    },
                  }
                : null;
            }
            if (direction === scenarioDirectionEnum.RetailCrm) {
              const isSitesCompared = scenarioData[direction]?.sites;
              return isSitesCompared
                ? {
                    0: {
                      value: 'on',
                      type: optionSettingType.Site,
                    },
                  }
                : null;
            }
            const isSettingExist = scenarioData[direction]?.[type]?.[status] || {};
            const isLeadInstalled = () => {
              if (isSettingExist.lead) {
                if (isBitrix24Integration) {
                  const leadObject = isSettingExist.lead as bitrixSettingLeadType;
                  return {
                    0: {
                      value: leadObject.responsible || leadObject.defaultResponsible || 'on',
                      type: optionSettingType.Lead,
                    },
                  };
                }
                if (isAmoCRMIntegration) {
                  const leadObject = isSettingExist.lead as amoSettingLeadType;
                  return {
                    0: {
                      value:
                        leadObject.pipeline_id ||
                        leadObject.responsible ||
                        leadObject.defaultResponsible ||
                        'on',
                      type: optionSettingType.Lead,
                    },
                  };
                }
              }
              if (isSettingExist.unsorted) {
                return {
                  0: {
                    value: isSettingExist.unsorted.pipeline_id || 'on',
                    type: optionSettingType.Unsorted,
                  },
                };
              }
              if (isSettingExist.contact) {
                return {
                  0: {
                    value: isSettingExist.contact.defaultResponsible || 'on',
                    type: optionSettingType.Contact,
                  },
                };
              }
              return undefined;
            };
            const isTaskInstalled = () => {
              if (isSettingExist.task) {
                if (typeof isSettingExist.task === 'boolean') {
                  return { 1: { value: 'on', type: optionSettingType.Task } };
                }
                return {
                  1: {
                    value:
                      isSettingExist.task.responsible ||
                      isSettingExist.task.defaultResponsible ||
                      'on',
                    type: optionSettingType.Task,
                  },
                };
              }
              return undefined;
            };
            return isSettingExist && Object.keys(isSettingExist).length !== 0
              ? { ...isLeadInstalled(), ...isTaskInstalled() }
              : null;
          }
          return null;
        };

        return (
          <Flex
            justifyContent={'spaceBetween'}
            alignItems={'center'}
            key={`scenarioList-${scenarioItemIndex}`}
            className={classes.integrationScenarioLine}
          >
            <div className={classes.defaultElementWidth40}>
              <div className={classes.integrationScenarioNameTitle}>
                <Typography type={'text3'} color={'tertiary900'} bold>
                  {translate(scenarioItem.title, { crmName })}
                </Typography>
              </div>
              {scenarioItem.options.map((optionItem, optionIndex) => {
                const isScenarioSettingInstalled: ISettingInstalledProps | null = isSettingOn();
                const isResponsibleFilled = isResponsibleScenario && responsibleData;
                const isOptionInstalled = isScenarioSettingInstalled
                  ? isScenarioSettingInstalled[optionIndex]?.value
                  : undefined;
                const isInstalled = () => {
                  if (isResponsibleScenario) {
                    return !!responsibleData;
                  }
                  return (isOptionInstalled || isScenarioAlwaysOn) && integrationId;
                };

                return (
                  <Flex
                    key={`scenarioOption-${optionIndex}`}
                    alignItems={'center'}
                    className={classes.defaultElementWidth40}
                  >
                    <div className={classes.integrationScenarioIcon}>
                      {isInstalled() ? (
                        <CheckIcon className={classes.integrationCheckIcon} />
                      ) : (
                        <PointIcon className={classes.integrationPointIcon} />
                      )}
                    </div>
                    <div>
                      {isResponsibleFilled ? (
                        <ScenarioResponsibleLine responsibleData={responsibleData} />
                      ) : (
                        <ScenarioSettingsLine
                          APIName={APIName}
                          integrationId={integrationId}
                          scenarioItem={scenarioItem}
                          optionIndex={optionIndex}
                          isOptionInstalled={isOptionInstalled}
                          isScenarioSettingInstalled={isScenarioSettingInstalled}
                          direction={direction}
                          comparedRawEmployee={rawEmployee}
                          crmName={crmName}
                        />
                      )}
                    </div>
                  </Flex>
                );
              })}
            </div>
            {(integrationId || !isPreview) && (
              <Flex className={classes.defaultElementWidth8} justifyContent={'center'}>
                {isScenarioAlwaysOn ? (
                  <Typography type={'text3'} color={'primary700'}>
                    {translate('ALWAYS_WORK')}
                  </Typography>
                ) : (
                  // eslint-disable-next-line react/jsx-no-useless-fragment
                  <>
                    {isInformationDialog ? (
                      <Button
                        className={classes.scenarioSettingButton}
                        onClick={() => onOtherScenarioEdit()}
                        clear
                      >
                        <Typography underline type={'text3'} color={'link600'} bold>
                          {translate('NOW_TO_CONFIG')}
                        </Typography>
                      </Button>
                    ) : (
                      <Button
                        title={translate('CONFIG')}
                        className={classes.scenarioSettingButton}
                        disabled={
                          (!isEmployeeListAvailable && !isResponsibleScenario) ||
                          !isSettingsAvailable
                        }
                        onClick={() => onScenarioEdit(scenarioItem)}
                        variant={'secondary'}
                      />
                    )}
                  </>
                )}
              </Flex>
            )}
          </Flex>
        );
      });
    };

    return (
      <div>
        <Flex
          justifyContent={'spaceBetween'}
          className={clsx({
            [classes.integrationNumberTabsBg]: !isScenarioTabsAvailable,
            [classes.integrationNumberTabs]: !isRoistatIntegration,
            [classes.integrationNumberTabsTitle]: isRoistatIntegration || !isPreview,
            [classes.integrationNumberTabsFull]: isPreview,
          })}
        >
          <div>{renderScenarioTabs(scenarioActiveTab)}</div>
          {integrationSettings.length > 1 &&
            !(isGravitelIntegration || isAPIIntegration || isSimpleCallsIntegration) && (
              <Button onClick={() => onScenarioDelete(scenarioIndex)} clear>
                <Typography type={'text3'} color={'danger600'}>
                  {translate('REMOVE_SCENARIO')}
                </Typography>
              </Button>
            )}
        </Flex>
        {isPreview ? null : (
          <RadioGroup defaultValue={'allNumbers'} name={'scenarioNumbers'}>
            {renderMainScenarioTabContent(scenarioActiveTab)}
          </RadioGroup>
        )}
      </div>
    );
  };

  return renderScenarioTabsContent();
};

export default memo(ScenariosActiveSettingsTab);
