import React from 'react';
import Button from '@shared/components/Button';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useControlButtonsStyles } from './ControlButtons.styles';
import { ControlButtonsProps } from './ControlButtons.interfaces';

export const ControlButtons = ({
  loading,
  confirmTitle,
  confirmDisable,
  rootConfirmStyles,
  confirmColor,
  confirmClassName,
  cancelTitle,
  cancelVariant = 'tertiary',
  cancelClassName,
  form,
  rootClass,
  onConfirmClick,
  onCancelClick,
  rootCancelBtn,
  flexDirection,
  justifyContent = 'space-between',
  cancelUnderline,
  small,
}: ControlButtonsProps) => {
  const classes = useControlButtonsStyles();
  const [translate] = useTranslation();
  const rootContainer = clsx(classes.root, rootClass, {
    [classes.rootJustifyCenter]: justifyContent === 'center',
    [classes.rootJustifyEvenly]: justifyContent === 'space-evenly',
    [classes.rootJustifyBetween]: justifyContent === 'space-between',
    [classes.rootJustifyStart]: justifyContent === 'start',
    [classes.rootJustifyEnd]: justifyContent === 'end',
    [classes.rootRowReverse]: flexDirection === 'row-reverse',
  });
  const cancelComputedClass = clsx(classes.cancelButton, rootCancelBtn, {
    [classes.underline]: cancelUnderline,
    [small ? classes.cancelButtonSmallMarginLeft : classes.cancelButtonMarginLeft]:
      flexDirection !== 'row-reverse',
    [small ? classes.cancelButtonSmallMarginRight : classes.cancelButtonMarginRight]:
      flexDirection === 'row-reverse',
    [classes.smallButtonHeight]: small,
  });

  const rootConfirmButtonClass = clsx(rootConfirmStyles, small && classes.smallButtonHeight);

  function handleConfirmClick() {
    if (onConfirmClick) {
      onConfirmClick();
    }
  }

  return (
    <div className={rootContainer}>
      <Button
        title={translate(confirmTitle)}
        loading={loading}
        type={form ? 'submit' : 'button'}
        disabled={confirmDisable}
        className={clsx(rootConfirmButtonClass, confirmClassName)}
        form={form}
        onClick={handleConfirmClick}
        color={confirmColor}
      />
      <Button
        className={clsx(cancelComputedClass, cancelClassName)}
        title={translate(cancelTitle)}
        disabled={loading}
        variant={cancelVariant}
        onClick={onCancelClick}
      />
    </div>
  );
};

export default ControlButtons;
