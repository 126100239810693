import React, { FunctionComponent, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation, useQuery } from '@apollo/client';
import { TIMEZONES_QUERY, USER_QUERY } from '@/client/queries';
import { UPDATE_DOMAIN_MUTATION } from '@/client/mutations';
import SelectField, { ISelectFieldOptionRendererProps } from '@shared/components/SelectField';
import { usePrevious } from '@/common/hooks/usePrev.hooks';
import Typography from '@shared/components/Typography';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { CheckIcon } from '@shared/assets/images/icons';
import ConfirmDialog, { ConfirmAction, IConfirmState } from '@components/ConfirmDialog';
import { getCurrentDomain } from '@/utils/getCurrentDomain';
import clsx from 'clsx';
import PagePreloader from '@components/PagePreloader';
import { ITimezone } from './SettingsTimeZone.interfaces';
import { useSettingsPagesStyles } from '../SettingsPage.styles';
import { SettingsPageTemplate } from '../SettingsPageTemplate';

interface IFormFields {
  timezoneId: number;
}

export const SettingsTimeZone: FunctionComponent = () => {
  const { data: { user } = {}, loading } = useQuery(USER_QUERY, { fetchPolicy: 'cache-first' });
  const { data: { timezones = [] } = {}, loading: timezonesLoading } = useQuery(TIMEZONES_QUERY);
  const prevLoading = usePrevious(loading);
  const [translate] = useTranslation();
  const navigate = useNavigate();
  const [updateDomain, { loading: timezoneUpdateLoading }] = useMutation(UPDATE_DOMAIN_MUTATION, {
    refetchQueries: [{ query: USER_QUERY }],
  });
  const domain = getCurrentDomain(user);
  const classes = useSettingsPagesStyles();
  const formMethods = useForm<IFormFields>();
  const { handleSubmit, reset, watch } = formMethods;
  const selectValue = watch('timezoneId');
  const [blockedPath, setBlockedPath] = useState<string | null>(null);
  const [state, setState] = useState<IConfirmState>({
    isBlocking: false,
    action: ConfirmAction.Edit,
  });

  const sliceHours = (number: string, separator?: string | undefined) => {
    const pattern = /^[-]0+|[+]0+/;
    const result = number.split(':');
    const hours = result[0].match(pattern) ? result[0].replace(/0/, '') : result[0];
    return `${result[0] !== '' ? hours : '0'}${separator || ''}`;
  };

  const addOffset = (hours: string, offset: string) => {
    const offsetHours = Number(offset.slice(1, 3));
    let fullHours;
    if (offset.includes('-')) {
      fullHours = (Number(hours) - offsetHours) % 24;
    } else {
      fullHours = (Number(hours) + offsetHours) % 24;
    }
    return fullHours <= 9 ? `0${fullHours}` : fullHours;
  };

  useEffect(() => {
    if (domain) {
      reset({
        timezoneId:
          domain?.timezoneId !== null && Number.isFinite(Number(domain?.timezoneId))
            ? Number(domain?.timezoneId)
            : undefined,
      });
    }
  }, [domain, reset]);

  useEffect(() => {
    setState({
      isBlocking: domain?.timezoneId !== selectValue,
      action: ConfirmAction.Edit,
    });
  }, [selectValue, domain?.timezoneId]);

  useEffect(() => {
    if (state.action === ConfirmAction.Cancel && !state.isBlocking) {
      navigate('/settings');
    }
  }, [state, navigate]);

  useEffect(() => {
    if (!loading && prevLoading) {
      if (domain) {
        reset({
          timezoneId:
            domain?.timezoneId !== null && Number.isFinite(Number(domain?.timezoneId))
              ? Number(domain?.timezoneId)
              : undefined,
        });
      }
    }
  }, [reset, domain, loading, prevLoading]);

  function getBlockedPath(path: string) {
    setBlockedPath(path);
  }

  function handleTimeZoneFormSubmit({ timezoneId }: IFormFields) {
    if (timezoneUpdateLoading) return;
    setState({
      isBlocking: false,
      action: ConfirmAction.Finish,
    });
    updateDomain({
      variables: {
        data: {
          timezoneId: Number(timezoneId),
        },
      },
    }).then(() => {
      navigate(blockedPath || '/settings');
    });
  }

  function handleCancelChanges() {
    setState({
      isBlocking: false,
      action: ConfirmAction.Cancel,
    });
  }

  const renderTimezoneOption = ({ data, content }: ISelectFieldOptionRendererProps<ITimezone>) => {
    const { label, mskOffset, offset, id } = data;

    if (!offset) {
      return (
        <div className={classes.selectText}>
          <Typography color={'tertiary900'} type={'text3'}>
            {label}
          </Typography>
        </div>
      );
    }

    const date = new Date();
    const isoDate = date.toISOString();
    const isoHours = isoDate.substr(11, 2);
    const mins = isoDate.substr(14, 2);
    const hours = addOffset(isoHours, offset);
    const time = `${hours}:${mins}`;
    const selected = id === selectValue;
    const selectedColor = selected ? 'primary700' : 'tertiary900';
    const selectedZoneColor = selected ? 'primary700' : 'tertiary600';

    return (
      <div className={classes.selectText}>
        <div className={classes.selectTime}>
          {selected && <CheckIcon className={classes.selectIcon} />}
          <div className={clsx(!selected && !content && classes.selectIconAbsenceMarginLeft)} />
          <Typography color={selectedColor} type={'text3'}>
            {time}
          </Typography>
        </div>
        <div className={classes.selectLabel}>
          <Typography color={selectedColor} type={'text3'}>
            {label}
          </Typography>
        </div>
        <div className={classes.selectTimeZone}>
          <Typography color={selectedZoneColor} type={'text3'}>
            MSK {sliceHours(mskOffset, ';')} UTC {sliceHours(offset)}
          </Typography>
        </div>
      </div>
    );
  };

  if (loading || timezonesLoading) {
    return <PagePreloader />;
  }

  if (!domain) {
    return <div className={classes.message}>{'Error...'}</div>;
  }

  return (
    <SettingsPageTemplate
      form={'settings-timezone'}
      title={'TIMEZONE'}
      onCancel={handleCancelChanges}
      isLoading={timezoneUpdateLoading}
    >
      <div className={classes.description}>
        <Typography color={'tertiary900'} type={'text3'}>
          {translate('SETTING_TIMEZONE_DESCRIPTION')}
        </Typography>
      </div>
      <FormProvider {...formMethods}>
        <form
          className={classes.form}
          onSubmit={handleSubmit(handleTimeZoneFormSubmit)}
          id={'settings-timezone'}
        >
          <SelectField
            name={'timezoneId'}
            valueKey={'id'}
            titleKey={'label'}
            data={timezones}
            SelectProps={{ classes: { root: classes.selectRoot } }}
            Renderer={renderTimezoneOption}
          />
        </form>
        <ConfirmDialog
          isBlocked={state.isBlocking}
          onNavigationBlocked={getBlockedPath}
          onSaveChanges={handleSubmit(handleTimeZoneFormSubmit)}
        />
      </FormProvider>
    </SettingsPageTemplate>
  );
};
