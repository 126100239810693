import React, { FunctionComponent, useMemo, useState } from 'react';
import Button from '@shared/components/Button';
import Flex from '@shared/components/Flex';
import Typography from '@shared/components/Typography';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';
import { USER_QUERY } from '@/client/queries';
import { getCurrentClient } from '@/utils';
import { globalNotification$ } from '@components/GlobalSnackbarNotification';
import downloadFileSafe from '@shared/utils/downloadFileSafe';
import { useMediaQuery } from '@material-ui/core';
import { WIDE_SIDEBAR_STATE } from '@components/client/queries';
import BodyContainer from '@/layouts/BodyContainer';
import { Route, Routes } from 'react-router-dom';
import WelcomeDialog from '@/components/WelcomePage/WelcomeDialog';
import Translate from '@shared/components/Translate';
import { useDesktopPageStyle } from './DesktopPage.styles';
import {
  CallStatisticsSectionWidget,
  BalanceWidget,
  TelephonyWidget,
  CallHourlyStatisticsSectionWidget,
} from './modules';
import { desktopPageSize } from './DesktopPage.interfaces';

export const DesktopPage: FunctionComponent = () => {
  const classes = useDesktopPageStyle();
  const [translate] = useTranslation();

  const [isDownloading, setIsDownloading] = useState(false);
  const { data: userData } = useQuery(USER_QUERY, { fetchPolicy: 'cache-first' });
  const { data: sidebarState } = useQuery(WIDE_SIDEBAR_STATE);
  const isWide = sidebarState?.isWide || false;
  const { account = '', atCommerceFrom } = getCurrentClient(userData?.user) || {};
  const isMinPageMaxWidth = isWide ? '1600px' : '1440px';
  const isNormalPageMaxWidth = isWide ? '1800px' : '1660px';

  const isMinPageSize = useMediaQuery(`(max-width:${isMinPageMaxWidth})`, { noSsr: true });
  const isNormalPageSize = useMediaQuery(`(max-width:${isNormalPageMaxWidth})`, { noSsr: true });

  const isOldClient = useMemo(
    () => atCommerceFrom && new Date(atCommerceFrom).getTime() < new Date('2024-11-01').getTime(),
    [atCommerceFrom]
  );
  const setPageSize = () => {
    if (isMinPageSize) {
      return {
        pageSize: desktopPageSize.Min,
        fontSize: '16px',
      };
    }
    if (isNormalPageSize) {
      return {
        pageSize: desktopPageSize.Normal,
        fontSize: '18px',
      };
    }
    return {
      pageSize: desktopPageSize.Wide,
      fontSize: '20px',
    };
  };

  const handleDownload = async () => {
    if (isDownloading) {
      return;
    }

    setIsDownloading(true);

    const result = await downloadFileSafe(
      '/Уведомление о перезаключении договоров и изменении цен.pdf',
      'Уведомление о перезаключении договоров и изменении цен.pdf'
    );
    if (result.type === 'error') {
      globalNotification$.show('danger', translate('COULD_NOT_DOWNLOAD_FILE'));
    }

    setIsDownloading(false);
  };

  const { fontSize, pageSize } = setPageSize();

  const renderDesktopContent = () => (
    <Flex direction={'column'} className={classes.root}>
      <Flex className={classes.body}>
        <div className={classes.desktopDataRoot}>
          <Flex className={classes.desktopPanelsCurrentPBS}>
            <Typography type={'text3'} color={'tertiary900'} bold>
              {`${translate('PBX_ACCOUNT')}:`}
            </Typography>
            <Typography
              className={classes.desktopPanelsCurrentPBSName}
              type={'text3'}
              color={'tertiary700'}
            >
              {account}
            </Typography>
          </Flex>
          <div style={{ fontSize }}>
            {isOldClient && (
              <div>
                <Flex className={classes.banner}>
                  <Flex
                    direction="column"
                    justifyContent={'spaceBetween'}
                    className={classes.bannerLeft}
                  >
                    <Typography
                      type={'text2'}
                      color={'inherit'}
                      className={classes.bannerText}
                      bold
                    >
                      <Translate i18nKey={'NEW_CONTRACTS_BANNER'} />
                    </Typography>
                    <Button
                      variant={'secondary'}
                      title={translate('MORE_INFO')}
                      className={classes.actionButton}
                      onClick={handleDownload}
                    />
                  </Flex>
                </Flex>
              </div>
            )}
            <Flex justifyContent={'spaceBetween'} className={classes.desktopPanelsWrapper}>
              <BalanceWidget pageSize={pageSize} />
              <TelephonyWidget />
              <CallStatisticsSectionWidget pageSize={pageSize} />
              <CallHourlyStatisticsSectionWidget />
            </Flex>
          </div>
        </div>
      </Flex>
    </Flex>
  );

  return (
    <BodyContainer>
      {renderDesktopContent()}
      <Routes>
        <Route path={'welcome'} element={<WelcomeDialog />} />
      </Routes>
    </BodyContainer>
  );
};

export default DesktopPage;
