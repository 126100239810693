import React, { FunctionComponent } from 'react';
import Typography from '@shared/components/Typography';
import { ICellProps } from '@components/LazyTable';
import Flex from '@shared/components/Flex';
import TooltipHover from '@shared/components/Popover/TooltipHover';
import Button from '@shared/components/Button';
import { XIcon } from '@shared/assets/images/icons';
import { useHistoryStyle } from '@/features/History/HistoryPage.styles';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { IHistoryList } from '../HistoryPage.interfaces';

export const HeaderTimeCell: FunctionComponent<Omit<ICellProps<IHistoryList>, 'item'>> = ({
  column,
}) => {
  const { label } = column;
  const classes = useHistoryStyle();
  const [translate] = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const sFromTime = searchParams.get('fromTime');
  const sToTime = searchParams.get('toTime');

  function handleResetTime() {
    searchParams.delete('fromTime');
    searchParams.delete('toTime');
    setSearchParams(searchParams);
  }

  const renderTimeHeader = () => {
    if (sFromTime && sToTime) {
      return (
        <Flex alignItems={'center'}>
          <TooltipHover
            placement={'bottom'}
            title={
              <Typography color={'tertiary900'} type={'text4'}>
                {`${sFromTime} - ${sToTime}`}
              </Typography>
            }
            tooltipClass={classes.tooltip}
            arrowClass={classes.arrow}
          >
            <Typography color={'primary700'} type={'text4'}>
              {translate(label)}
            </Typography>
          </TooltipHover>
          <Button onClick={() => handleResetTime()} clear>
            <XIcon className={classes.cellCloseIcon} />
          </Button>
        </Flex>
      );
    }
    return (
      <Typography color={'tertiary400'} type={'text4'}>
        {translate(label)}
      </Typography>
    );
  };

  return renderTimeHeader();
};
