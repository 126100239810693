import { DEPARTMENT_QUERY } from '@/client/queries';
import { useLazyQuery } from '@apollo/client';
import FramelessAccordion from '@components/FramelessAccordion';
import TooltipHover from '@shared/components/Popover/TooltipHover';
import { IDepOrEmployees, IDepartmentUser } from '@components/typings/interfaces';
import { formatPhone } from '@components/utils/phoneNumbers/phoneNumbers';
import { EmailIcon, HelpCircleIcon, SmsIcon, TrashIcon } from '@shared/assets/images/icons';
import { Button } from '@shared/components/Button/Button';
import CheckboxField from '@shared/components/CheckboxField';
import ComboBoxField from '@shared/components/ComboBoxField';
import Flex from '@shared/components/Flex';
import Typography from '@shared/components/Typography';
import clsx from 'clsx';
import React, { FunctionComponent, useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { IRecipientCardType } from '../../SettingsNotifications.interface';
import { NotificationStyles } from '../Notification.styles';

export const RecipientCard: FunctionComponent<IRecipientCardType> = ({
  recipient,
  cardIndex,
  data,
  onDelete,
  onChange,
  error,
  userDomain = '',
}: IRecipientCardType) => {
  const classes = NotificationStyles();
  const [translate] = useTranslation();
  const { setValue } = useFormContext();
  const recipientCardClasses = clsx(classes.recipientCard, {
    [classes.recipientCardNormal]: !error,
    [classes.recipientCardError]: !!error,
  });
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore false positive check for ErrorOption
  const { message: errorMessage = '' } = error || {};
  const recipientEmail = recipient?.email;
  const isRecipientDepartment = recipient?.type === 'DEPARTMENTS';
  const isDomainEmail = useMemo(
    () =>
      recipientEmail && recipientEmail?.slice(recipientEmail.lastIndexOf('@') + 1) === userDomain,
    [recipientEmail, userDomain]
  );

  // TODO handle lazy query error
  const [getDepartment, { data: departmentData }] = useLazyQuery(DEPARTMENT_QUERY);
  const departmentEmployees = departmentData?.department?.employees;

  useEffect(() => {
    if (recipient) {
      const idAsNumber = Number(recipient.id);
      const idAsNumberIsFinite = Number.isFinite(idAsNumber);
      if (idAsNumberIsFinite) {
        getDepartment({ variables: { id: idAsNumber } });
      }
      setValue(`selectedRecipient-${cardIndex}`, recipient.listId);
      setValue(`smscheckbox-${cardIndex}`, recipient.smsNotification);
      setValue(`emailcheckbox-${cardIndex}`, recipient.emailNotification);
      if (isDomainEmail) {
        setValue(`emailcheckbox-${cardIndex}`, false);
      }
    } else {
      setValue(`selectedRecipient-${cardIndex}`, undefined);
    }
  }, [setValue, recipient, cardIndex, isDomainEmail, getDepartment, isRecipientDepartment]);

  function handleCheckboxChange(
    inputValue: boolean,
    notificationType?: 'emailNotification' | 'smsNotification'
  ) {
    if (notificationType && recipient) {
      onChange({ ...recipient, [notificationType]: inputValue }, cardIndex);
    }
  }

  function handleRecipientChange(inputValue: IDepOrEmployees) {
    let { emailNotification, smsNotification } = inputValue;
    const isInputValueDomainEmail =
      inputValue.email &&
      inputValue.email?.slice(inputValue.email.lastIndexOf('@') + 1) === userDomain;
    if (emailNotification === undefined) {
      emailNotification =
        inputValue.type === 'DEPARTMENTS'
          ? !!inputValue.emailsCount
          : !!inputValue.email && !isInputValueDomainEmail;
    }
    if (smsNotification === undefined) {
      smsNotification =
        inputValue.type === 'DEPARTMENTS' ? !!inputValue.phonesCount : !!inputValue.phone;
    }
    const hasNotificationMethod = emailNotification || smsNotification;
    onChange(
      { ...inputValue, emailNotification, smsNotification, hasNotificationMethod },
      cardIndex
    );
  }

  const renderNotificationTooltip = () => (
    <div>
      <TooltipHover
        className={classes.notificationIcon}
        placement={'right'}
        title={
          <Typography color={'tertiary900'} type={'text4'}>
            {translate('SPECIFY_REAL_EMAIL_FOR_NOTIFICATIONS')}
          </Typography>
        }
      >
        <HelpCircleIcon />
      </TooltipHover>
    </div>
  );

  const renderDepartmentCard = (i: number) => (
    <>
      <div className={classes.recipientCardTop}>
        <Typography type={'text3'} color={'tertiary900'}>
          {recipient?.employeesCount !== 0
            ? `${translate('EMPLOYEES_COUNT')}: ${recipient?.employeesCount}`
            : translate('DEPT_NO_EMPLOYEES')}
        </Typography>
        <Button className={classes.trashIcon} variant={'tertiary'} onClick={() => onDelete(i)}>
          <TrashIcon />
        </Button>
      </div>
      <div className={classes.marginBottom1}>
        <Typography type={'text4'} color={'tertiary700'}>
          {translate('NOTIFICATION_METHODS')}
        </Typography>
      </div>
      <div className={classes.redirectRow}>
        <div className={classes.redirectType}>
          <CheckboxField
            name={`smscheckbox-${i}`}
            label={translate('SMS')}
            disabled={!recipient?.phonesCount}
            defaultValue={!!recipient?.phonesCount}
            onChange={(isChecked) => handleCheckboxChange(isChecked, 'smsNotification')}
          />
        </div>
        <SmsIcon />
        <Typography className={classes.redirectText} type={'text3'} color={'tertiary900'}>
          {`${translate('COUNT_NUMBERS')}: ${recipient?.phonesCount}`}
        </Typography>
      </div>
      <div className={classes.redirectRow}>
        <div className={classes.redirectType}>
          <CheckboxField
            name={`emailcheckbox-${i}`}
            label={translate('EMAIL')}
            disabled={!recipient?.emailsCount}
            defaultValue={!!recipient?.emailsCount}
            onChange={(isChecked) => handleCheckboxChange(isChecked, 'emailNotification')}
          />
        </div>
        <EmailIcon />
        <Typography className={classes.redirectText} type={'text3'} color={'tertiary900'}>
          {`${translate('ADDRESSES')}: ${recipient?.emailsCount}`}
        </Typography>
      </div>
      <FramelessAccordion expandTitle={translate('EXPAND_LIST')} lessTitle={translate('LESS_LIST')}>
        {departmentEmployees?.map(({ user, phone }: IDepartmentUser) => (
          <Flex key={`employee-${user.id}`}>
            <div className={clsx(classes.expandBlockLeft)}>
              <div className={classes.marginTop1}>
                <Typography
                  className={clsx(classes.textOverflow, classes.textWrapper)}
                  type={'text3'}
                  color={'tertiary900'}
                >
                  {user.name}
                </Typography>
              </div>
            </div>
            <div className={classes.expandBlockRight}>
              <div className={classes.expandContactsWrapper}>
                <div className={classes.redirectRow}>
                  <SmsIcon className={classes.marginRight1} />
                  <Typography type={'text3'} color={'tertiary600'}>
                    {phone ? formatPhone(phone) : translate('NUMBER_NOT_SPECIFIED')}
                  </Typography>
                </div>
                <div className={classes.redirectRow}>
                  <EmailIcon className={classes.marginRight1} />
                  <Typography type={'text3'} color={'tertiary600'}>
                    {user.email}
                  </Typography>
                  {user?.email?.slice(user.email.lastIndexOf('@') + 1) === userDomain &&
                    renderNotificationTooltip()}
                </div>
              </div>
            </div>
          </Flex>
        ))}
      </FramelessAccordion>
    </>
  );

  const renderEmployeeCard = (i: number) => (
    <>
      <div className={classes.recipientCardTop}>
        <Typography type={'text4'} color={'tertiary700'}>
          {translate('NOTIFICATION_METHODS')}
        </Typography>
        <Button className={classes.trashIcon} variant={'tertiary'} onClick={() => onDelete(i)}>
          <TrashIcon />
        </Button>
      </div>
      <div className={classes.redirectRow}>
        <div className={classes.redirectType}>
          <CheckboxField
            name={`smscheckbox-${i}`}
            label={translate('SMS')}
            defaultValue={!!recipient?.phone}
            disabled={!recipient?.phone}
            onChange={(isChecked) => handleCheckboxChange(isChecked, 'smsNotification')}
          />
        </div>
        <SmsIcon />
        <Typography className={classes.redirectText} type={'text3'} color={'tertiary900'}>
          {formatPhone(recipient?.phone || '') || translate('NUMBER_NOT_SPECIFIED')}
        </Typography>
      </div>
      <div className={classes.redirectRow}>
        <div className={classes.redirectType}>
          <CheckboxField
            name={`emailcheckbox-${i}`}
            label={translate('EMAIL')}
            defaultValue={!!recipient?.email}
            disabled={!recipient?.email || !!isDomainEmail}
            onChange={(isChecked) => handleCheckboxChange(isChecked, 'emailNotification')}
          />
        </div>
        <EmailIcon />
        <Typography className={classes.redirectText} type={'text3'} color={'tertiary900'}>
          {recipient?.email || translate('SELECT_EMPLOYEE_OR_DEPARTMENT')}
        </Typography>
        {isDomainEmail && renderNotificationTooltip()}
      </div>
    </>
  );

  return (
    <div className={classes.notificationRecipientContainer}>
      <div className={classes.notificationFieldContainer}>
        <ComboBoxField
          name={`selectedRecipient-${cardIndex}`}
          label={translate('NOTIFICATION_RECIPIENT')}
          data={data}
          valueKey={'listId'}
          titleKey={'name'}
          groupBy={(option: { type: string }) => translate(option.type || '', '')}
          placeholder={translate('CHOOSE')}
          onChange={(event, inputValue) => handleRecipientChange(inputValue)}
        />
      </div>
      {recipient !== undefined && (
        <div>
          <div className={recipientCardClasses}>
            {isRecipientDepartment
              ? renderDepartmentCard(cardIndex)
              : renderEmployeeCard(cardIndex)}
          </div>
          <div className={classes.recipientCardErrorText}>
            <Typography type={'text5'} color={'danger600'}>
              {translate(errorMessage)}
            </Typography>
          </div>
        </div>
      )}
    </div>
  );
};
