import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core';
import { IThemeOptions } from '@components/theme';

export const useOutgoingCallsCommonNumberStyles = makeStyles(
  ({ color: { primary } }: IThemeOptions & Theme) => ({
    form: {
      display: 'flex',
      flexDirection: 'column',
      lineHeight: 'normal',
    },
    formContent: {
      width: '23em',
      marginBottom: '2em',
      display: 'flex',
      flexDirection: 'column',
      marginTop: '1.5em',
    },
    secondaryText: {
      marginTop: '0.4em',
    },
    customOption: {
      display: 'flex',
      flexFlow: 'column nowrap',
      pointerEvents: 'none',
      height: '4.375em',
      justifyContent: 'center',
      width: '100%',
      padding: '0.75em 2.615em 0.75em 1.3em',
      '&:hover': {
        background: primary[400],
        backgroundColor: primary[400],
      },
    },
    customOptionFlexMarginLeft: {
      marginLeft: '1.5em',
    },
    customOptionSelected: {
      display: 'flex',
      flexFlow: 'column nowrap',
      pointerEvents: 'none',
      height: '4.375em',
      justifyContent: 'center',
      width: '100%',
      padding: '0.75em 2.615em 0.75em 1.3em',
    },
    glyph: {
      marginRight: '0.5em',
      color: primary[700],
    },
  })
);
