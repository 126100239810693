import { makeStyles } from '@material-ui/core';

export const useComboBoxFieldStyles = makeStyles(
  ({ color: { base, primary, tertiary } }) => ({
    root: {
      padding: 0,
      '& .MuiAutocomplete-inputRoot.MuiOutlinedInput-root.MuiOutlinedInput-marginDense': {
        padding: 0,
      },
    },
    hasPopupIcon: {
      '& $inputRoot[class*="MuiOutlinedInput-root"], ': {
        paddingRight: 'initial',
        padding: 'initial',
      },
      '& $inputRoot[class*="MuiOutlinedInput-root"][class*="MuiOutlinedInput-marginDense"]': {
        padding: 'initial',
      },
    },
    endAdornment: {},
    hasClearIcon: {
      '& $inputRoot[class*="MuiOutlinedInput-root"]': {
        paddingRight: 'initial',
        padding: 'initial',
        '&$input': {
          '&:first-child': {
            paddingLeft: 'initial',
            padding: 'initial',
          },
        },
      },
      '& $inputRoot[class*="MuiOutlinedInput-root"][class*="MuiOutlinedInput-marginDense"]': {
        padding: 'initial',
      },
    },
    inputRoot: {
      '&[class*="MuiOutlinedInput-root"]': {
        '& $input': {
          '&:first-child': {
            padding: '0.75em 3em 0.75em 1em',
          },
        },
        '& $endAdornment': {
          top: 'initial',
          right: 'initial',
        },
      },
    },
    input: {
      '&:first-child': {
        paddingLeft: 'initial',
        padding: 'initial',
      },
    },
    focused: {},
    paper: {
      margin: 0,
      background: base,
      '& .MuiAutocomplete-noOptions': {
        fontSize: '0.875em',
      },
    },
    option: {
      '&[data-focus="true"]': {
        background: tertiary[100],
      },
      '&[aria-selected="true"]': {
        background: 'transparent',
        opacity: 1,
      },
      minHeight: 'initial',
      padding: 0,
      height: 'initial',
    },
    groupUl: {
      '& $option': {
        paddingLeft: 0,
      },
    },
  }),
  {
    name: 'comboBoxField',
    index: 1,
  }
);

export const useComboBoxFieldOptionStyles = makeStyles(
  ({ color: { primary } }) => ({
    root: {
      padding: '0.75em 3em 0.75em 1em',
      display: 'flex',
      flexFlow: 'row nowrap',
      overflow: 'hidden',
    },
    text: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    textMarginLeft: {
      marginLeft: '2em',
    },
    glyph: {
      minWidth: '1em',
      minHeight: '1em',
      marginTop: '2px',
      marginRight: '0.75em',
      color: primary[700],
    },
  }),
  {
    name: 'comboBoxFieldOption',
    index: 1,
  }
);

export const useAdditionalStyles = makeStyles(({ color: { primary } }) => ({
  highlight: {
    color: primary[700],
  },
}));
