import React from 'react';
import Typography from '@shared/components/Typography';
import { useTranslation } from 'react-i18next';
import { ILazyTableHeaderCellType } from './HeaderCell.interfaces';
import Cell from '../Cell';

export const headerCellDefaultTextColor = 'tertiary500';

export const HeaderCell = <T,>({ column }: ILazyTableHeaderCellType<T>) => {
  const { HeaderRenderer, label } = column;
  const [translate] = useTranslation();

  return (
    <Cell isHeader column={column} className={column.classNameHeader}>
      {HeaderRenderer ? (
        <HeaderRenderer column={column} />
      ) : (
        <Typography bold color={headerCellDefaultTextColor} type={'text4'}>
          {translate(label)}
        </Typography>
      )}
    </Cell>
  );
};

export default HeaderCell;
