export enum NumberType {
  Demo = 'Demo',
  Gravitel = 'Gravitel',
  GravitelCommon = 'GravitelCommon',
  Runexis = 'Runexis',
  Own = 'Own',
  OwnTrunk = 'OwnTrunk',
  Carousel = 'Carousel',
}

export enum NumberStatus {
  Free = 'Free',
  Reserved = 'Reserved',
  Bought = 'Bought',
  Quarantine = 'Quarantine',
  WillDisabled = 'WILL_DISABLED',
}

export enum NumberCategory {
  Simple = 'Simple',
  Classic = 'Classic',
  Beautiful = 'Beautiful',
  Vip = 'Vip',
  VipPlus = 'VipPlus',
}

export interface INumberTariff {
  id: number;
  category: string;
  setupFee: number;
  setupDiscount: number;
  isSetupDiscountAbsolute: boolean;
  monthlyFee: number;
  monthlyDiscount: number;
  isMonthlyDiscountAbsolute: boolean;
  baseMonthlyFee: number;
  baseSetupFee: number;
}

export interface IDomainNumber {
  id: number;
  numberCatalogId: number;
  phone: string;
  name?: string;
  city?: string;
  cityName?: string;
  category?: string;
  type: NumberType;
  status: NumberStatus;
  statusTill?: string;
  tariff: INumberTariff;
  createdAt?: string;
  updatedAt?: string;
}

export enum NumberOperationStatus {
  Free = 'Free',
  Reserved = 'Reserved',
  Bought = 'Bought',
  Refuse = 'RefuseRequest',
  AbortRefuse = 'RefuseAbort',
  Quarantine = 'Quarantine',
}

export enum NumberOperationStatusEntity {
  Reserved = 'reserved',
  Bought = 'bought',
  Refuse = 'refuse',
  SelectAll = 'selectAll',
}
