import React, { FunctionComponent, MouseEvent } from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useBreadcrumbStyles } from './Breadcrumb.styles';
import { IRoute, useRoutes } from '../Routes';

export const Breadcrumb: FunctionComponent<IRoute> = ({
  path = '',
  titleCode = '',
  breadCrumbTitle,
  excluded,
  query = '',
}) => {
  const [translate] = useTranslation();
  const classes = useBreadcrumbStyles();
  const {
    path: { category, subcategory, identifier },
  } = useRoutes();

  if (excluded) {
    return null;
  }

  let to = path === '/' ? '' : path;

  if (query) {
    to = `${path}?${query}`;
  }

  const text = translate(breadCrumbTitle || titleCode || '', titleCode);
  let currentPath = `/${category}`;
  if (subcategory) currentPath += `/${subcategory}`;
  if (identifier) currentPath += `/${identifier}`;

  function handleNavLinkClick(e: MouseEvent<HTMLAnchorElement>) {
    if (path === currentPath) e.preventDefault();
  }

  return (
    <NavLink
      to={to}
      onClick={handleNavLinkClick}
      title={text}
      className={classes.root}
      activeClassName={classes.active}
    >
      {text}
    </NavLink>
  );
};

export default Breadcrumb;
