import { IDomainDetails } from './domain';

export enum ClientType {
  Fl = 'Fl',
  Ul = 'Ul',
  Ip = 'Ip',
}

export enum Gender {
  Female = 'female',
  Male = 'male',
}

export interface IClient {
  id: number;
  name: string;
  type: ClientType;
  contract?: string;
}

export interface ILegalPassport {
  id: number;
  name: string;
  person: string;
  genitiveCasePerson: string;
  genitiveCasePosition: string;
  actBasis: string;
  position: string;
  inn: string;
  kpp: string;
  ogrn: string;
  legalAddress: string;
  postalAddress: string;
  bankName: string;
  account: string;
  bik: string;
  correspondentAccount: string;
  bankAddress: string;
  validFrom: Date;
  validTo: Date;
  createdAt: Date;
  phones: string[];
  emails: string[];
}

export interface IIndividualPassport {
  id: number;
  name: string;
  inn: number;
  gender: Gender;
  legalAddress: string;
  postalAddress: string;
  series: string;
  number: string;
  placeOfIssue: string;
  dateOfIssue: Date;
  departmentCode: string;
  scans: string[];
  validFrom: Date;
  validTo: Date;
  createdAt: Date;
  phones: string[];
  emails: string[];
}

export interface IClientDetails {
  id: number;
  name: string;
  type: ClientType;
  isModerated: boolean;
  contract: string;
  balance: number;
  credit: number;
  manager?: {
    id: number;
    name: string;
  };
  seller?: {
    id: number;
    name: string;
  };
  partner?: {
    id: number;
    name: string;
  };
  params?: {
    contacts?: {
      phones?: string[];
      emails?: string[];
    };
  };
  legalPassport?: ILegalPassport;
  individualPassport?: IIndividualPassport;
  domains: IDomainDetails;
}

export type suggestCompanyType = {
  address: string;
  shortAddress: string;
  inn: string;
  kpp?: string | null | undefined;
  name: string;
  fullName: string;
  ogrn: string;
  person?: string | null | undefined;
  position?: string | null | undefined;
};

export type suggestBankType = {
  name: string;
  shortName: string;
  bik: string;
  correspondentAccount?: string | null | undefined;
  address: string;
};

export type suggestFMSType = {
  name: string;
  code: string;
};

export type suggestCompanyItemType = {
  key: string;
  value: string;
  companyName: string;
  data: suggestCompanyType;
};

export type suggestBankItemType = {
  key: string;
  value: string;
  bankName: string;
  data: suggestBankType;
};

export type suggestFMSItemType = {
  key: string;
  value: string;
  departmentCode: string;
  data: suggestFMSType;
};

export type formDataArray = {
  [key: string]: string | string[];
};

export interface IUpdateClientPassport {
  type: ClientType;
  personName: string;
  phone: string;
  email: string;
  legalAddress: string;
  postalAddress: string;
  gender?: string;
  series?: string;
  number?: string;
  placeOfIssue?: string;
  dateOfIssue?: string;
  departmentCode?: string;
  companyName?: string;
  genitiveCasePerson?: string;
  actBasis?: string;
  position?: string;
  inn?: string;
  kpp?: string;
  ogrn?: string;
  bankName?: string;
  account?: string;
  bankShortName?: string;
  bik?: string;
  correspondentAccount?: string;
  bankAddress?: string;
  passportFrontFileHash?: string;
  passportFrontFileName?: string;
  passportFrontFileUrl?: string;
  passportAddressFileHash?: string;
  passportAddressFileName?: string;
  passportAddressFileUrl?: string;
  innFileHash?: string;
  innFileName?: string;
  innFileUrl?: string;
  kndFileHash?: string;
  kndFileName?: string;
  kndFileUrl?: string;
  phones?: string[];
  emails?: string[];
}

export enum ClientStatus {
  Demo = 'Demo',
  Moderating = 'Moderating',
  Confirmed = 'Confirmed',
  Commercial = 'Commercial',
  Blocked = 'FinBlocked',
  PreDeleted = 'PreDeleted',
  Deleted = 'Deleted',
  Unknown = 'Unknown',
}
