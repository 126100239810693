import { DomainAllNumbersQuery, NumberType } from '@/client/generated/graphql';
import {
  ScenariosTypes,
  SchedulingMode,
  ServerSchedule,
} from '../IncomingNumber/IncomingNumber.interfaces';
import { ISchedulePeriod } from '../IncomingNumber/Scheduler/Scheduler.interfaces';

export enum IncomingNumberStatusType {
  Reserved = 'Reserved',
  On = 'ON',
  Off = 'OFF',
  Restricted = 'RESTRICTED',
}

export interface IIncomingNumber {
  id: number;
  name?: string;
  atc?: SchedulingMode;
  hasSchedule?: boolean;
  periods?: Array<ISchedulePeriod>;
  scheduler?: ServerSchedule;
  responsible?: IResponsible;
  integrations?: Array<IntegrationList>;
  city?: string;
  phone?: string;
  domainId?: number;
  prefix?: string;
  status?: IncomingNumberStatusType;
  type?: NumberType;
  demo?: boolean;
  statusTill?: boolean;
  routeName?: string;
  audioUrl?: string;
  destination?: IIncomingNumberSettings;
  copiedId?: string;
}

export type IncomingNumber = DomainAllNumbersQuery['domainNumbers'][number];

export interface IResponsible {
  id: number;
  name: string;
  type: string;
}

export type IntegrationList = {
  id: number;
  name: string;
  type: string;
  status: string;
  settings?: {
    next?: number | string;
    nextId?: number;
    playInfo?: boolean;
    dialTimeout?: number;
  };
};

export interface IIncomingNumberSettings {
  [key: string]: IIncomingNumberScenario;
}

export interface IIncomingNumberScenario {
  id?: string;
  type: ScenariosTypes;
  emails?: IVoiceMenuEmail[];
  button?: string;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const deserializeIncomingNumber = (data: any = {}) => {
  const {
    id,
    name = '',
    city,
    domainId,
    phone,
    prefix,
    status,
    demo,
    statusTill,
    hasSchedule,
    periods,
    scheduler,
  } = data;
  return {
    id,
    domainId,
    name,
    city,
    phone,
    prefix,
    status: status || IncomingNumberStatusType.Off,
    demo,
    statusTill,
    hasSchedule,
    periods,
    scheduler,
  };
};

export interface IVoiceMenuEmail {
  id?: number;
  email?: string;
  type: string;
}

export enum IVoiceMailEmailType {
  Employee = 'EMPLOYEES',
  Department = 'DEPARTMENTS',
  Plain = 'PLAIN',
}
